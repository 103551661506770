<template>
  <div class="model-box newsletter-g">
    <div class="title">
      <div class="ui-title">{{ modelData.title }}</div>
    </div>

    <div class="hr"></div>

    <div class="edit">
      <div
        v-html="htmlDecode(modelData.edit || '')"
        class="ui-edit"
        style="height: 57px; color: #3e556a; font-size: 15px"
      ></div>
    </div>

    <div class="img-list">
      <div class="ui-img-box">
        <div class="ui-img">
          <div
            class="s-img"
            :style="'background-image: url(' + modelData.img1 + ');'"
          ></div>
        </div>
      </div>

      <div class="ui-img-box">
        <div class="ui-img">
          <div
            class="s-img"
            :style="'background-image: url(' + modelData.img2 + ');'"
          ></div>
        </div>
      </div>

      <div class="ui-img-box">
        <div class="ui-img">
          <div
            class="s-img"
            :style="'background-image: url(' + modelData.img3 + ');'"
          ></div>
        </div>
      </div>

      <div class="ui-img-box">
        <div class="ui-img">
          <div
            class="s-img"
            :style="'background-image: url(' + modelData.img4 + ');'"
          ></div>
        </div>
      </div>

      <div class="ui-img-box">
        <div class="ui-img">
          <div
            class="s-img"
            :style="'background-image: url(' + modelData.img5 + ');'"
          ></div>
        </div>
      </div>

      <div class="ui-img-box">
        <div class="ui-img">
          <div
            class="s-img"
            :style="'background-image: url(' + modelData.img6 + ');'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { htmlDecode, formatImgData } from "./../../utils/utils.js";

export default {
  components: {},
  props: {
    data: {
      default: () => ({}),
      type: Object,
    },
    index: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      modelData: {},
    };
  },
  mounted() {},
  watch: {
    data: function () {
      this.modelData = this.formatImgData(this.data, 6);
    },
  },
  created() {
    this.modelData = this.formatImgData(this.data, 6);
  },
  methods: {
    htmlDecode,
    formatImgData,
  },
};
</script>
<style lang="less">
@import "~@/assets/css/journalModel.less";
</style>
