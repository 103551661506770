<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb :data="breadcrumbData" slot="breadcrumb" />
    <GgPage pageType="1" title="Students">
      <div>
        <GgPageHeader
          :title="`${studentStatusData.firstName || ''} ${
            studentStatusData.lastName || ''
          }`"
          :menuData="menuData"
          :subTitle1="studentStatusData.studentId"
          @menuCallback="onMenuCallback"
        >
          <div slot="title-label"></div>
          <div slot="avatarHeader" class="avatar">
            <div
              :class="
                studentDetailData.status === 0
                  ? 'green'
                  : studentDetailData.status === 2
                  ? 'absent'
                  : studentDetailData.status === 1
                  ? 'red'
                  : ''
              "
              class="statusImg"
            >
              <head-img
                class="imgBox"
                :src="studentStatusData.photo"
                line-height="52"
                :firstName="`${studentStatusData.firstName}`"
                :lastName="`${studentStatusData.lastName}`"
              />
            </div>
          </div>
          <div slot="info">
            <div class="title-status mar-b-0">
              {{ studentStatusData.status }}
            </div>
          </div>
        </GgPageHeader>
        <section-info :data="dataList" title="Timeline" :subTitle="getDate()">
          <template slot="custom-html">
            <edoovo-table
              v-if="!timelineList.length"
              height="100%"
              rowKey="id"
              defaultValue="-"
            >
              <!-- ===== -->
              <!-- 空状态 -->
              <!-- ===== -->
              <div slot="empty" style="text-align: center">
                <div
                  style="
                    width: 213px;
                    text-align: center;
                    border-radius: 50%;
                    margin-bottom: 12px;
                    line-height: 200px;
                  "
                >
                  <img
                    style="width: 100%"
                    :src="require('@/assets/images/products/noData.svg')"
                  />
                </div>
                <p style="font-size: 22px">No attendance info yet.</p>
              </div>
            </edoovo-table>
            <div class="timeline-wrap" v-else>
              <el-timeline>
                <el-timeline-item
                  v-for="(item, key) in timelineList"
                  :key="key"
                >
                  <!-- <i class="iconfont iconyiquxiao" slot="dot"></i> -->
                  <img
                    v-if="
                      item.action == 'Temperature' ||
                      item.action == 'In class temperature' ||
                      item.action == 'Ad hoc temperature'
                    "
                    style="width: 45px; margin-left: -18px"
                    :src="timelineTemperatureSvg"
                    alt=""
                    slot="dot"
                  />
                  <img
                    v-if="item.action === 'Check-in'"
                    style="width: 45px; margin-left: -18px"
                    :src="checkInSvg"
                    alt=""
                    slot="dot"
                  />
                  <img
                    v-if="item.action === 'Check-out'"
                    style="width: 45px; margin-left: -18px"
                    :src="checkOutSvg"
                    alt=""
                    slot="dot"
                  />
                  <p class="timeline-time">{{ dateFormat(item.checkTime) }}</p>
                  <div @click="onSelectedStudentItem(item)">
                    <p>
                      {{ item.action }}
                      <span
                        v-if="item.edit"
                        style="
                          margin-left: 5px;
                          color: #b4b4b4;
                          font-size: 12px;
                        "
                        >Edited</span
                      >
                    </p>
                    <p style="font-family: 'Roboto'">
                      <span v-if="item.temperatrue" style="margin-right: 10px"
                        >Temperature : {{ item.temperatrue }}°C</span
                      >
                      <span v-if="item.byUserFirstName || item.byUserLastName">
                        {{ getDes(item) }}
                        {{
                          item.visitor ||
                          (item.action != "Check-out" &&
                            item.action != "Check-in")
                            ? ""
                            : item.relationShip == "Default role"
                            ? ""
                            : item.relationShip
                        }}
                        <head-img
                          v-if="!item.visitor"
                          :src="
                            item.deleted
                              ? item.byUserPhoto
                              : item.visitor == false &&
                                item.action != 'Check-out' &&
                                item.action != 'Check-in'
                              ? item.byUserPhoto
                              : item.dropOffOrPickUpPhoto
                          "
                          :firstName="item.byUserFirstName"
                          :lastName="item.byUserLastName"
                          line-height="20"
                          style="display: inline-block; vertical-align: middle"
                          class="smft"
                        />
                        {{ item.byUserFirstName }} {{ item.byUserLastName }}
                      </span>
                    </p>
                    <div
                      style="padding-top: 10px"
                      v-if="
                        (item.action === 'Check-in' ||
                          item.action == 'In class temperature' ||
                          item.action == 'Ad hoc temperature') &&
                        !item.deleted
                      "
                    >
                      <p
                        class="icon-wrap"
                        style="margin-right: 8px"
                        v-if="item.symptomsList"
                      >
                        <i
                          class="icon iconfontNew icona-SymptomsIcon_24dp"
                          style="font-size: 16px"
                          :title="
                            item.symptomsList && item.symptomsList.join(',')
                          "
                        ></i>
                      </p>
                      <p class="icon-wrap" v-if="item.remarks">
                        <i
                          class="icon iconfontNew icona-RemarkIcon_24dp"
                          style="font-size: 16px"
                          :title="item.remarks"
                        ></i>
                      </p>
                    </div>
                  </div>
                </el-timeline-item>
              </el-timeline>
            </div>
          </template>
        </section-info>
      </div>
    </GgPage>
    <!-- <div class="wraper"></div> -->
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "Timeline",
  components: {},
  data() {
    return {
      breadcrumbData: [],
      timelineTemperatureSvg: require("@/assets/images/icons/timeline_temperature.svg"),
      checkInSvg: require("@/assets/images/icons/check_in.svg"),
      checkOutSvg: require("@/assets/images/icons/check_out.svg"),
      queryDate: "",
      studentDetailData: {},
      studentStatusData: {},
      // studentData: {},
      timelineList: [],
      chooseCheckInOutItem: {},
      checkInDialogVisible: false,
      checkOutDialogVisible: false,
      temperatureDialogVisible: false,
      checkInDialogVisible1: false,
      checkOutDialogVisible1: false,
      temperatureDialogVisible1: false,
      absentDialogVisible: false,
      isEditable: true,
      dataList: [],
      snackbarData: {
        visible: false,
        content: "",
      },
      menuData: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      breadcrumb: (state) => state.breadcrumb,
    }),
  },
  created() {
    this.breadcrumbData = this.breadcrumb.breadcrumbData;
    // if(sessionStorage.getItem('date')){
    // 	this.queryDate = Utils.formatTime(sessionStorage.getItem('date'), '{y}-{m}-{d}')
    // }else{
    // this.queryDate = Utils.formatTime(Date.now(), '{y}-{m}-{d}')
    // }
    this.queryDate = moment(this.$route.query.date, ["DD/MM/YYYY"]).format(
      "YYYY-MM-DD"
    );
    // this.studentData = JSON.parse(this.$route.query.studentData || '{}')
    this.queryTimelineData();
    // this.handleMenuDataStatus()
    this.queryStudentDetail();
    this.queryStudentStatus();
    this.studentDetailData.status = this.$route.query.status;
  },
  methods: {
    getDate() {
      return moment(this.$route.query.date, ["DD/MM/YYYY"]).format(
        "dddd, DD MMMM YYYY"
      );
    },
    getDes(item) {
      if (item.operationRecord != "" && item.operationRecord != null) {
        return item.operationRecord;
      } else {
        item.action == "Check-out";
        if (item.action == "Check-in") {
          if (item.visitor) {
            return "Drop-off by: visitor:";
          } else {
            return "Drop-off by:";
          }
        } else if (item.action == "Check-out") {
          if (item.visitor) {
            return "Pick-off: visitor";
          } else {
            return "Pick-up:";
          }
        } else if (
          item.action == "Temperature" ||
          item.action == "In class temperature" ||
          item.action == "Ad hoc temperature"
        ) {
          return "Taken by:";
        } else if (item.action == "Absent") {
          return "Marked by:";
        }
      }
    },
    dateFormat(date) {
      if (!date) {
        return "-";
      }
      return moment
        .utc(date, ["YYYY-MM-DD hh:mm:ss A"])
        .local()
        .format("h:mm A");
    },
    onDateClosed(val) {
      let tim = Date.parse(val);
      this.queryDate = Utils.formatTime(tim, "{y}-{m}-{d}");
      this.queryTimelineData();
      this.queryStudentDetail();
      this.queryStudentStatus();
    },
    onForwardDate(val) {
      // this.getData(val);
      console.log("forward", val);
    },
    onNextDate(val) {
      // this.getData(val);
      console.log("next", val);
    },
    handleMenuDataStatus() {},
    onMenuCallback(item) {
      this.isEditable = false;
      if (item.label === "Check in") {
        this.checkInDialogVisible1 = true;
      } else if (item.label === "Check out") {
        this.checkOutDialogVisible1 = true;
      } else if (item.label === "Add temperature") {
        this.temperatureDialogVisible1 = true;
      } else if (item.label === "Mark as absent") {
        this.absentDialogVisible = true;
      }
    },
    onCheckIn(paramsDetail) {
      console.log(paramsDetail);
      console.log(this.studentDetailData);
      let url = this.isEditable
        ? "/attendance/checkInOut/updateCheckIn"
        : "/attendance/checkInOut/checkIn";
      let params = {
        timeLine: paramsDetail,
      };
      Object.assign(params.timeLine, {
        checkInOutId: this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.studentDetailData.schoolId,
        studentId: this.studentDetailData.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
        userType: paramsDetail.dropUserType == "pickup" ? 1 : 0,
        startDate: moment(moment(this.queryDate).format("YYYY-MM-DD"))
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(moment(this.queryDate).format("YYYY-MM-DD"))
          .utc()
          .add(24, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      });
      params.startDate = moment(moment(this.queryDate).format("YYYY-MM-DD"))
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      params.endDate = moment(moment(this.queryDate).format("YYYY-MM-DD"))
        .utc()
        .add(24, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      Ajax.post(url, params).then((res) => {
        if (res.code === "0000") {
          this.checkInDialogVisible = false;
          this.checkInDialogVisible1 = false;
          this.snackbarData = {
            visible: true,
            content: "Student check-in info has been edited",
          };
          this.queryTimelineData();
          this.queryStudentDetail();
        }
      });
    },
    onCheckOut(paramsDetail) {
      let url = this.isEditable
        ? "/attendance/checkInOut/updateCheckOut"
        : "/attendance/checkInOut/checkOut";
      let params = {
        timeLine: paramsDetail,
      };
      Object.assign(params.timeLine, {
        checkInOutId: this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.studentDetailData.schoolId,
        studentId: this.studentDetailData.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
        userType: paramsDetail.dropUserType == "pickup" ? 1 : 0,
        startDate: moment(moment(this.queryDate).format("YYYY-MM-DD"))
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(moment(this.queryDate).format("YYYY-MM-DD"))
          .utc()
          .add(24, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
        status: 1,
      });
      params.startDate = moment(moment(this.queryDate).format("YYYY-MM-DD"))
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      params.endDate = moment(moment(this.queryDate).format("YYYY-MM-DD"))
        .utc()
        .add(24, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      Ajax.post(url, params).then((res) => {
        if (res.code === "0000") {
          this.checkOutDialogVisible = false;
          this.checkOutDialogVisible1 = false;
          this.snackbarData = {
            visible: true,
            content: "Student check-out info has been edited",
          };
          this.queryTimelineData();
          this.queryStudentDetail();
        }
      });
    },
    onAddTemperature(tempValue) {
      let url = this.isEditable
        ? "/attendance/timeLine/updateTemp"
        : "/attendance/timeLine/addTemp";
      let params;

      if (this.isEditable) {
        params = {
          checkInOutId: this.isEditable
            ? this.chooseCheckInOutItem.id
            : this.studentDetailData.id, //this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
          operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
          temperature: tempValue,
          // time: Utils.getDateNowByAmPm(), // '10:00:00 AM'
          // id:this.chooseCheckInOutItem.id
        };
      } else {
        params = {
          checkInOutId: this.isEditable
            ? this.chooseCheckInOutItem.id
            : this.studentDetailData.id, //this.studentDetailData.id, // this.isEditable ? this.chooseCheckInOutItem.id : this.studentDetailData.id,
          operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
          temperature: tempValue,
          // time: Utils.getDateNowByAmPm(), // '10:00:00 AM'
          time: moment().format("DD/MM/YYYY hh:mm A"),
          // id:this.chooseCheckInOutItem.id
        };
      }

      Ajax.post(url, params).then((res) => {
        if (res.code === "0000") {
          this.temperatureDialogVisible = false;
          this.temperatureDialogVisible1 = false;
          this.snackbarData = {
            visible: true,
            content: "Student temperature has been edited",
          };
          this.queryTimelineData();
          this.queryStudentDetail();
        }
      });
    },
    onSaveAbsent(data) {
      let params = {
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.studentDetailData.schoolId,
        studentId: this.$route.query.studentId,
        classId: this.$route.query.classId,
        absentTime: moment().format("DD/MM/YYYY hh:mm A"),
        startDate: moment(moment().format("YYYY-MM-DD"))
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(moment().format("YYYY-MM-DD"))
          .utc()
          .add(24, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      Ajax.post("/attendance/checkInOut/markAsAbsent", params).then((res) => {
        if (res.code === "0000") {
          this.absentDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.queryTimelineData();
          this.queryStudentDetail();
        }
      });
    },
    onSelectedStudentItem(row) {
      if (row.action == "Absent") {
        return false;
      }
      if (row.operationRecord != "" && row.operationRecord != null) {
        return false;
      }
      this.chooseCheckInOutItem = row;
      this.isEditable = true;
      if (row.action === "Check-in") {
        this.checkInDialogVisible = true;
      } else if (row.action === "Check-out") {
        this.checkOutDialogVisible = true;
      } else {
        this.temperatureDialogVisible = true;
      }
    },
    queryStudentDetail() {
      Ajax.get("/attendance/checkInOut/getCheckInOutByStudentId", {
        studentId: this.$route.query.studentId,
        schoolId: this.user.locationId,
        startDate: moment(moment(this.queryDate).format("YYYY-MM-DD"))
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(moment(this.queryDate).format("YYYY-MM-DD"))
          .utc()
          .add(24, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      }).then((res) => {
        this.studentDetailData = res.data;
        this.studentDetailData.studentId = this.$route.query.studentId;
        this.handleMenuDataStatus();
      });
    },
    queryStudentStatus() {
      Ajax.get("/usermanage/student/selectByStuId", {
        stuId: this.$route.query.studentId,
      }).then((res) => {
        this.studentStatusData = res.data;
      });
    },
    queryTimelineData() {
      let params = {
        // checkInOutId: this.$route.query.id, // || "2CEXyDX6kTtsu8vNvnangGeFmSinZqTA",
        studentId: this.$route.query.studentId,
        startDate: moment(this.queryDate).utc().format("YYYY-MM-DD HH:mm:ss"),
        classId: this.$route.query.classId,
      };
      Ajax.get("/attendance/timeLine/getTimeLineEnhanceV1", params).then(
        (res) => {
          this.timelineList = res.data;
          if (res.data.length == 0) {
            this.studentDetailData.status = null;
          } else {
            if (
              res.data[0].action == "Temperature" ||
              res.data[0].action == "Check-in"
            ) {
              this.studentDetailData.status = 0;
            } else if (res.data[0].action == "Check-out") {
              this.studentDetailData.status = 1;
            } else {
              this.studentDetailData.status = 2;
            }
          }
          this.handleMenuDataStatus();
        }
      );
    },
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        showClose: false,
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      });
      //  .then(() => {
      //   this.handleSuspend("dddd");
      // });
    },
    handleDeleteTemperature(id) {
      let messageObj = {
        title: "Delete temperature?",
        desc: "Once deleted, you will not be able to recover the data.",
        cancelTxt: "Cancel",
        confirmTxt: "Delete",
      };
      let callback = () => {
        this.delCheckInOutData(id, "temperature");
      };
      this.openMessageBox(messageObj, callback);
    },
    handleDeleteCheckIn(id) {
      let messageObj = {
        title: "Delete check in info?",
        desc: "Once deleted, you will not be able to recover the data.",
        cancelTxt: "Cancel",
        confirmTxt: "Delete",
      };
      let callback = () => {
        this.delCheckInOutData(id, "checkIn");
      };
      this.openMessageBox(messageObj, callback);
    },
    handleDeleteCheckOut(id) {
      let messageObj = {
        title: "Delete check out info?",
        desc: "Once deleted, you will not be able to recover the data.",
        cancelTxt: "Cancel",
        confirmTxt: "Delete",
      };
      let callback = () => {
        this.delCheckInOutData(id, "checkOut");
      };
      this.openMessageBox(messageObj, callback);
    },
    delCheckInOutData(id, category) {
      let content = "Delete success";
      if (category == "checkIn") {
        content = "Check in info has been deleted";
      } else if (category == "checkOut") {
        content = "Check out info has been deleted";
      } else if (category == "temperature") {
        content = "Temperature has been deleted";
      }
      let params = {
        id,
        operationUser: this.user.userId, // || '3fa7f627dda84f3e914f79863e0eda0b',
      };
      Ajax.post("/attendance/timeLine/delete", {}, {}, params).then((res) => {
        this.queryTimelineData();
        this.queryStudentDetail();
        this.snackbarData = { visible: true, content: content };
        this.checkInDialogVisible = false;
        this.checkOutDialogVisible = false;
        this.temperatureDialogVisible = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类

/deep/ .ggPage-main-left {
  display: none;
}
.timeline-wrap {
  padding: 20px 0px 0px 120px;
  .timeline-time {
    position: absolute;
    left: -100px;
    font-size: 12px;
    color: #202124;
    font-family: "Roboto";
  }
  .icon-wrap {
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    // padding: 5px;
    // width: 30px;
    // height: 30px;
    // line-height: 30px;
    text-align: center;
    border: 1px solid #000;
    border-radius: 100%;
  }
}

.statusImg {
  display: inline-block;
  /* padding: 3px; */
  width: 58px;
  height: 58px;
  border-radius: 50%;
  /* background-color: #ddd; */
  /* margin-right: 5px; */
}
.imgBox {
  margin: 3px;
}
/deep/.ggPageTitle-left .avatar {
  background-color: white;
}
/deep/.previewImg {
  display: none;
}
/deep/.md-field .md-input,
.md-field .md-textarea {
  font-size: 14px;
}
/deep/.md-field label {
  font-size: 14px;
}
/deep/.el-dialog {
  width: 585px;
}
/deep/.section-info-header-right .title {
  font-family: "Roboto";
  color: #5f6368;
  font-size: 14px;
}

/deep/.edoovo-table-empty {
  padding: 80px 32px;
}
</style>
