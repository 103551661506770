<template>
  <div class="headernav">
    <div class="topAppBar">
      <div class="topAppBar-left">
        <div class="circleBtn" @click="setMenuVisible">
          <i class="material-icons iconfont iconapps" />
        </div>
        <div class="title" @click="setMenuVisible">{{ title }}</div>
        <div class="leftOther">
          <slot></slot>
        </div>
      </div>
      <div class="topAppBar-right">
        <!-- <div class="circleBtn">
          <i class="material-icons iconfont iconhelp_outline" />
        </div>
        <div class="circleBtn">
          <i class="material-icons iconfont iconsettings" />
        </div> -->
        <!-- userInfoCard -->
        <div class="avatar">
          <el-popover
            placement="bottom"
            :visible-arrow="false"
            trigger="click"
            popper-class="header-user-popover"
            :width="288"
          >
            <AvatarCard :userInfo="userInfo" />
            <div class="user_head" slot="reference">
              <head-img
                :src="userInfo && userInfo.photo ? `${userInfo.photo}` : ``"
                :firstName="userInfo.firstName"
                :lastName="userInfo.lastName"
              />
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div @click="setMenuVisible">
      <el-menu
        default-active="2"
        :class="{ 'el-menu-vertical': true, show_menu: show_menu }"
      >
        <sidebar-item :menuItems="appDatas"></sidebar-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
import SidebarItem from "@/components/private-components/SidebarItem";
import { mapState } from "vuex";
import { Ajax } from "@/common";
export default {
  name: "TopAppBar",
  components: {
    SidebarItem,
  },
  data() {
    return {
      avatarHash: "",
      show_menu: false,
      appDatas: [],
      menus: [
        {
          id: "adminConsole",
          name: "Admin Console",
          rouerterName: "adminConsole",
          multiple: false,
        },
        {
          id: "staff",
          name: "Staff",
          rouerterName: "staff",
          multiple: false,
        },
        {
          id: "students",
          name: "Students",
          rouerterName: "students",
          multiple: false,
        },
        {
          id: "companies",
          name: "Companies",
          rouerterName: "companies",
          multiple: false,
        },
        {
          id: "parents",
          name: "Parents",
          rouerterName: "parents",
          multiple: false,
        },
        {
          id: "classes",
          name: "Classes",
          rouerterName: "classes",
          multiple: true,
        },
        {
          id: "people",
          name: "People",
          rouerterName: "people",
          multiple: true,
        },
        {
          id: "attendance",
          name: "Attendance",
          rouerterName: "attendance",
          multiple: true,
        },
        {
          id: "commbook",
          name: "Commbook",
          rouerterName: "commbook",
          multiple: true,
        },
        {
          id: "announcement",
          name: "Announcements",
          rouerterName: "announcement",
          multiple: true,
        },
        {
          id: "moments",
          name: "Moments",
          rouerterName: "moments",
          multiple: true,
        },
        {
          id: "journal",
          name: "Journal",
          rouerterName: "journal",
          multiple: true,
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) this.show_menu = false;
    });
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    userInfo() {
      if (!this.user.userInfo) {
        let obj = {
          firstName: "",
          lastName: "",
          photo: "",
          email: "",
        };
        return obj;
      }

      return this.user.userInfo;
    },
    filterMenus() {
      // let rolePermission = this.user.rolePermission;
      // return this.menus.filter((item) => {
      //   return rolePermission.indexOf(item.name) != -1;
      // });
    },
    // userInfoData: {

    // },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    userInfo() {
      this.avatarHash = +new Date();
    },
  },
  methods: {
    handle() {
      console.log(123123);
    },
    setMenuVisible() {
      this.show_menu = !this.show_menu;
    },
    // _getSvg(menu) {
    //   if (menuIconParams[menu] && menuIconParams[menu].svg) {
    //     return menuIconParams[menu].svg;
    //   }
    //   return "product_app";
    // },
    _topItemClick(item) {
      // if (this.menusTo[item.id]) {
      //   this.show_menu = false;
      //   this.$router.push({
      //     path: this.menusTo[item.id]
      //   });
      // } else {
      this.$message("The app is still under development and not usable yet.");
      // }
    },
    getLocation() {
      let rolePermission = this.user.rolePermission;
      this.appDatas = this.menus.filter((item) =>
        rolePermission.some((item1) => item1 === item.name)
      );
      let isLocation = false;

      isLocation = this.appDatas.some((item) => item.multiple);
      Ajax.get("/usermanage/location/selectLocationByUserId", {
        userId: this.user.userId,
      }).then((res) => {
        if (res.data.length > 1 && isLocation) {
          this.appDatas = this.appDatas.filter((item) => {
            return !item.multiple;
          });

          this.appDatas.push({
            id: "locations",
            name: "Locations",
            rouerterName: "locations",
            multiple: false,
          });
        } else {
          // this.appDatas = this.appData;
          if (res.data.length == 0) {
            this.$store.commit("SET_LOCATIONID", "");
          } else {
            this.$store.commit("SET_LOCATIONID", res.data[0].id);
          }
        }
      });
    },
  },
  created() {
    this.getLocation();
  },
};
</script>

<style lang="less">
.header-user-popover {
  padding: 0;
  margin-top: 38px !important;
  transform: translateX(-14px);
}
</style>
<style lang="less" scoped>
.topAppBar {
  height: 64px;
  line-height: 64px;
  background: #1a73e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;

  &-left {
    display: flex;
    align-items: center;
    // cursor: pointer;
    flex: 1;

    .title {
      min-width: 256px;
      font-size: 22px;
      font-family: OpenSans-Bold;
    }

    .circleBtn {
      margin: 0 4px;
    }

    .leftOther {
      flex: 1;
      position: relative;
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .avatar {
      margin-right: 12px;
      margin-left: 40px;
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 50%;
      font-family: OpenSans-Bold;
      font-size: 12px;
      text-align: center;
      color: #efefef;
      background-color: #b4b4b4;
      cursor: pointer;

      span {
        display: block;
      }

      .user_head {
        cursor: pointer;
        width: 32px;
        height: 32px;
        line-height: 32px;
        overflow: hidden;
        border-radius: 50%;
        background-color: #ddd;
        // border: 1px dashed #445469;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .circleBtn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    i {
      font-size: 24px;
      line-height: 24px;
    }
  }
}

.headernav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.el-menu-vertical {
  padding-top: 24px;
  position: absolute;
  transition: left 0.5s;
  left: -352px;
  top: 64px;
  width: 280px !important;
  height: 1020px;
  background: #fff;
  z-index: 999;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);

  &.show_menu {
    left: 0;
  }
}
</style>
