var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"recipients"},[(_vm.isSelect)?_c('base-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.selectStudentsDialogVisible = true}}},[_vm._v("Select students")]):_vm._e(),(_vm.isSelect)?_c('div',{staticClass:"selected-student-list"},_vm._l((_vm.chooseStudentObj),function(item,key){return _c('p',{directives:[{name:"show",rawName:"v-show",value:(item.count && item.studentList.length),expression:"item.count && item.studentList.length"}],key:key},[_c('head-img',{staticClass:"user-box",staticStyle:{"display":"inline-block","margin-right":"10px","vertical-align":"top"},style:({ backgroundColor: item.themeColor }),attrs:{"line-height":"28","firstName":item.studentList.length > 0 ? item.studentList[0].className : '',"lastName":item.studentList.length > 0
              ? item.studentList[0].className.split(' ').length > 1
                ? item.studentList[0].className.split(' ')[1]
                : ''
              : ''},on:{"click":function () {}}}),_vm._l((item.studentList),function(sItem,key){return _c('span',{key:key},[_vm._v(" "+_vm._s(sItem.firstName)+" "+_vm._s(sItem.lastName)+" "),_c('i',{staticClass:"el-icon-error",staticStyle:{"cursor":"pointer","color":"#cecece"},on:{"click":function($event){return _vm.onDelStudent(item.studentList, key)}}})])})],2)}),0):_vm._e(),_c('div',[_c('div',{staticStyle:{"overflow":"hidden","margin-bottom":"10px"}},[_c('md-field',{staticStyle:{"float":"left","width":"48%","background-color":"#f8f8f8"}},[_c('label',{attrs:{"for":"movie"}},[_vm._v("Topic")]),_c('md-select',{attrs:{"name":"movie","id":"movie"},model:{value:(_vm.paramsDetail.topic),callback:function ($$v) {_vm.$set(_vm.paramsDetail, "topic", $$v)},expression:"paramsDetail.topic"}},_vm._l((_vm.topicList),function(item,key){return _c('md-option',{key:key,attrs:{"value":item.value}},[_vm._v(_vm._s(item.value))])}),1)],1),_c('gg-input',{staticStyle:{"float":"right","width":"48%","background-color":"#f8f8f8"},attrs:{"placeholder":"Title","error":_vm.errorTitle},model:{value:(_vm.paramsDetail.title),callback:function ($$v) {_vm.$set(_vm.paramsDetail, "title", $$v)},expression:"paramsDetail.title"}})],1),_c('div',{staticClass:"edit-editing"},[_c('editor',{attrs:{"init":_vm.editorInit},model:{value:(_vm.paramsDetail.message),callback:function ($$v) {_vm.$set(_vm.paramsDetail, "message", $$v)},expression:"paramsDetail.message"}})],1),(_vm.errorMessage.show)?_c('div',{staticClass:"edit-error"},[_vm._v(" "+_vm._s(_vm.errorMessage.message)+" ")]):_vm._e(),_c('imageListPreview',{attrs:{"fileList":_vm.fileList},on:{"loading":_vm.changeloading}}),_c('div',{staticStyle:{"overflow":"hidden","padding-top":"20px"}},[_c('el-upload',{staticStyle:{"display":"inline-block"},attrs:{"auto-upload":false,"action":"","accept":"image/jpeg,image/png,application/pdf","show-file-list":false,"on-change":function (file, fileList) {
              _vm.onUploadFileChange(file, fileList);
            },"before-upload":_vm.beforeUpload,"disabled":_vm.fileList.length != 0}},[_c('baseButton',{staticClass:"contained",staticStyle:{"margin-top":"8px"},attrs:{"disabled":_vm.fileList.length != 0,"type":"primary"}},[_c('span',{staticClass:"icon iconfont iconattach_file-web"}),_vm._v("Add")])],1),_c('div',{staticStyle:{"float":"right"}},[_c('base-button',{on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('base-button',{attrs:{"title":!(
                _vm.paramsDetail.message &&
                _vm.paramsDetail.title &&
                _vm.paramsDetail.topic
              ) ||
              (_vm.isSelect && JSON.stringify(_vm.chooseStudentObj) == '{}') ||
              _vm.errorTitle.show ||
              _vm.errorMessage.show
                ? 'Cannot post without students,topic and message'
                : '',"type":"primary","disabled":!(
                _vm.paramsDetail.message &&
                _vm.paramsDetail.title &&
                _vm.paramsDetail.topic
              ) ||
              (_vm.isSelect && JSON.stringify(_vm.chooseStudentObj) == '{}') ||
              _vm.errorTitle.show ||
              _vm.errorMessage.show},on:{"click":_vm.onPost}},[_vm._v("Post")])],1)],1)],1),(_vm.isImgCutShow)?_c('img-cut-modal',{attrs:{"visible":_vm.isImgCutShow,"acceptPdfFile":true,"isCut":true},on:{"update:visible":function($event){_vm.isImgCutShow=$event},"_uploadCallback":_vm._uploadCallback,"_uploadPdfCallback":_vm._uploadPdfCallback}}):_vm._e(),_c('selectStudentsDialog',{attrs:{"dialogVisible":_vm.selectStudentsDialogVisible,"studentListObj":_vm.studentListObj,"selectedStudentObj":_vm.chooseStudentObj},on:{"cancel":function($event){_vm.selectStudentsDialogVisible = false},"save":_vm.onSelectStudents}}),_c('Snackbar',{attrs:{"visible":_vm.snackbar.isShow,"content":_vm.snackbar.content},on:{"update:visible":function($event){return _vm.$set(_vm.snackbar, "isShow", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }