var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":_vm.breadcrumbData},slot:"breadcrumb"}),_c('GgPage',{attrs:{"pageType":"1","title":"Students"}},[_c('div',[_c('GgPageHeader',{attrs:{"title":((_vm.studentStatusData.firstName || '') + " " + (_vm.studentStatusData.lastName || '')),"menuData":_vm.menuData,"subTitle1":_vm.studentStatusData.studentId},on:{"menuCallback":_vm.onMenuCallback}},[_c('div',{attrs:{"slot":"title-label"},slot:"title-label"}),_c('div',{staticClass:"avatar",attrs:{"slot":"avatarHeader"},slot:"avatarHeader"},[_c('div',{staticClass:"statusImg",class:_vm.studentDetailData.status === 0
                ? 'green'
                : _vm.studentDetailData.status === 2
                ? 'absent'
                : _vm.studentDetailData.status === 1
                ? 'red'
                : ''},[_c('head-img',{staticClass:"imgBox",attrs:{"src":_vm.studentStatusData.photo,"line-height":"52","firstName":("" + (_vm.studentStatusData.firstName)),"lastName":("" + (_vm.studentStatusData.lastName))}})],1)]),_c('div',{attrs:{"slot":"info"},slot:"info"},[_c('div',{staticClass:"title-status mar-b-0"},[_vm._v(" "+_vm._s(_vm.studentStatusData.status)+" ")])])]),_c('section-info',{attrs:{"data":_vm.dataList,"title":"Timeline","subTitle":_vm.getDate()}},[_c('template',{slot:"custom-html"},[(!_vm.timelineList.length)?_c('edoovo-table',{attrs:{"height":"100%","rowKey":"id","defaultValue":"-"}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No attendance info yet.")])])]):_c('div',{staticClass:"timeline-wrap"},[_c('el-timeline',_vm._l((_vm.timelineList),function(item,key){return _c('el-timeline-item',{key:key},[(
                    item.action == 'Temperature' ||
                    item.action == 'In class temperature' ||
                    item.action == 'Ad hoc temperature'
                  )?_c('img',{staticStyle:{"width":"45px","margin-left":"-18px"},attrs:{"slot":"dot","src":_vm.timelineTemperatureSvg,"alt":""},slot:"dot"}):_vm._e(),(item.action === 'Check-in')?_c('img',{staticStyle:{"width":"45px","margin-left":"-18px"},attrs:{"slot":"dot","src":_vm.checkInSvg,"alt":""},slot:"dot"}):_vm._e(),(item.action === 'Check-out')?_c('img',{staticStyle:{"width":"45px","margin-left":"-18px"},attrs:{"slot":"dot","src":_vm.checkOutSvg,"alt":""},slot:"dot"}):_vm._e(),_c('p',{staticClass:"timeline-time"},[_vm._v(_vm._s(_vm.dateFormat(item.checkTime)))]),_c('div',{on:{"click":function($event){return _vm.onSelectedStudentItem(item)}}},[_c('p',[_vm._v(" "+_vm._s(item.action)+" "),(item.edit)?_c('span',{staticStyle:{"margin-left":"5px","color":"#b4b4b4","font-size":"12px"}},[_vm._v("Edited")]):_vm._e()]),_c('p',{staticStyle:{"font-family":"'Roboto'"}},[(item.temperatrue)?_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v("Temperature : "+_vm._s(item.temperatrue)+"°C")]):_vm._e(),(item.byUserFirstName || item.byUserLastName)?_c('span',[_vm._v(" "+_vm._s(_vm.getDes(item))+" "+_vm._s(item.visitor || (item.action != "Check-out" && item.action != "Check-in") ? "" : item.relationShip == "Default role" ? "" : item.relationShip)+" "),(!item.visitor)?_c('head-img',{staticClass:"smft",staticStyle:{"display":"inline-block","vertical-align":"middle"},attrs:{"src":item.deleted
                            ? item.byUserPhoto
                            : item.visitor == false &&
                              item.action != 'Check-out' &&
                              item.action != 'Check-in'
                            ? item.byUserPhoto
                            : item.dropOffOrPickUpPhoto,"firstName":item.byUserFirstName,"lastName":item.byUserLastName,"line-height":"20"}}):_vm._e(),_vm._v(" "+_vm._s(item.byUserFirstName)+" "+_vm._s(item.byUserLastName)+" ")],1):_vm._e()]),(
                      (item.action === 'Check-in' ||
                        item.action == 'In class temperature' ||
                        item.action == 'Ad hoc temperature') &&
                      !item.deleted
                    )?_c('div',{staticStyle:{"padding-top":"10px"}},[(item.symptomsList)?_c('p',{staticClass:"icon-wrap",staticStyle:{"margin-right":"8px"}},[_c('i',{staticClass:"icon iconfontNew icona-SymptomsIcon_24dp",staticStyle:{"font-size":"16px"},attrs:{"title":item.symptomsList && item.symptomsList.join(',')}})]):_vm._e(),(item.remarks)?_c('p',{staticClass:"icon-wrap"},[_c('i',{staticClass:"icon iconfontNew icona-RemarkIcon_24dp",staticStyle:{"font-size":"16px"},attrs:{"title":item.remarks}})]):_vm._e()]):_vm._e()])])}),1)],1)],1)],2)],1)]),_c('Snackbar',{attrs:{"visible":_vm.snackbarData.visible,"content":_vm.snackbarData.content},on:{"update:visible":function($event){return _vm.$set(_vm.snackbarData, "visible", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }