<!--
 * @Description: 用户信息card组件
 * @Author: luozhong
 * @Date: 2020-08-13 22:24:17
-->
<template>
  <div class="AvatarCard">
    <div class="pop_head">
      <div
        @click="
          () => {
            this.isImgCutShow = true;
          }
        "
        class="avatar-uploader"
      >
        <head-img
          class="imgBox"
          :src="userInfo.photo"
          :firstName="userInfo.firstName"
          :lastName="userInfo.lastName"
          line-height="88"
        />
        <div class="iconBox">
          <i class="material-icons iconfont iconadd_a_photo" />
        </div>
      </div>
    </div>
    <p class="pop_name">{{ userInfo.firstName }} {{ userInfo.lastName }}</p>
    <p class="pop_email">{{ userInfo.email }}</p>
    <ul>
      <li>
        <button class="edoovo-custom-btn account-btn" @click="goMyAcc">
          Manage your account
        </button>
      </li>
      <li>
        <button class="edoovo-custom-btn logout-btn" @click="logout">
          Sign out
        </button>
      </li>
    </ul>
    <img-cut-modal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="_uploadCallback"
    />
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
export default {
  name: "AvatarCard",
  components: {},
  props: {
    userInfo: {
      type: Object,
      default: () => ({
        firstName: "",
        lastName: "",
      }),
    },
  },
  data() {
    return {
      isImgCutShow: false,
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  methods: {
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    goMyAcc() {
      this.$router.push("/account");
    },
    logout() {
      this.$router.replace({
        path: "/login",
      });
      sessionStorage.clear();
    },
    _uploadCallback(formData, fileData) {
      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.code === "0000") {
            this.updateUserInfo(
              `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
            );
            this.userInfo.photo = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
            this.$store.commit("SET_USER_INFO", this.userInfo);
          }
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },
    _beforeClose() {
      this.isImgCutShow = false;
    },
    updateUserInfo(val) {
      Ajax.post("/usermanage/user/updateUserInfoById", {
        userId: this.$store.state.user.userId,
        photo: val,
      }).then((res) => {
        if (res.code === "0000") {
          this.isImgCutShow = false;
          this.handleSnack("Photo has been edited");
        }
      });
    },
  },
  created() {},
};
</script>

<style lang="less">
.imgCutMoal {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>

<style lang="less" scoped>
.AvatarCard {
  height: 310px;
  width: 288px;
  background-color: #fff;
  padding: 24px 0;
  box-shadow: 0px 0px 6px #00000029;
  ul {
    text-align: center;
    .el-button {
      margin-top: 16px;
      margin-bottom: 32px;
      &.el-button--primary {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
  .pop_name {
    font-size: 16px;
    line-height: 22px;
    font-family: OpenSans-Bold;
    text-align: center;
    color: #202124;
    margin-top: 10px;
  }
  .pop_email {
    margin-top: 10px;
    font-family: Roboto;
    font-size: 14px;
    line-height: 17px;
    margin-top: 11px;
    text-align: center;
  }
  .pop_head {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background-color: #ddd;
    margin-top: 12px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    .avatar-uploader {
      width: 88px;
      height: 88px;
      border-radius: 50%;
      background-color: #ddd;
      position: relative;
      cursor: pointer;
      .imgBox {
        width: 88px;
        height: 88px;
        line-height: 88px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .iconBox {
        width: 32px;
        height: 32px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
        .icon {
          font-size: 19px;
          color: #445469;
        }
      }
    }
  }
  .edoovo-custom-btn {
    color: #5f6368;
    font-family: OpenSans-Bold;
    &.account-btn {
      margin-top: 20px;
      width: 168px;
      height: 32px;
      line-height: 30px;
      padding: 0;
    }
    &.logout-btn {
      margin-top: 32px;
      border-radius: 4px;
      padding: 0;
      width: 80px;
      height: 32px;
      line-height: 30px;
    }
  }
}
</style>
