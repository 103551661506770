<template>
  <div class="journal-inner" v-loading="loading">
    <div class="tool">
      <div
        class="download col-btn"
        v-if="hasPermission('Manage journals_Edit')"
        @click="createPdf('download', 1)"
      >
        <div class="col-tit">Download</div>
        <img src="@/assets/images/icons/download_black_24dp.svg" />
      </div>
      <!--     <div class="download col-btn" v-if="hasPermission('Manage journals_Edit')" @click="createPdf('download', 3)"><div class="col-tit">1.5倍</div><img src="@/assets/images/icons/download_black_24dp.svg" /></div>
      <div class="download col-btn" v-if="hasPermission('Manage journals_Edit')" @click="zoom()"><div class="col-tit">变大</div><img src="@/assets/images/icons/download_black_24dp.svg" /></div> -->
      <div
        class="print col-btn"
        v-if="hasPermission('Manage journals_Edit')"
        @click="createPdf('print')"
      >
        <div class="col-tit">Print</div>
        <img src="@/assets/images/icons/print-24px.svg" />
      </div>
    </div>
    <div class="view-carousel-inner" :class="type">
      <el-carousel
        @change="carouselChange"
        :autoplay="false"
        arrow="always"
        :loop="false"
        :height="height + 'px'"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="journal-page" ref="page">
            <components
              v-if="item.no"
              :data="item.data"
              :index="index"
              :is="item.no + '-s'"
            ></components>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="indicator">
        <span class="s">Page</span> {{ viewCarouselIndex }}
        <span class="t">/</span> {{ list.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from "moment";
import { mapState } from "vuex";
// import domtoimage from "dom-to-image";
import domtoimage from "dom-to-image-more";
import { htmlDecode, hasPermission } from "./../utils/utils.js";

// import { hasPermission } from './../utils/utils.js'
import newsletterAS from "./../modules/model/newsletterAS";
import newsletterBS from "./../modules/model/newsletterBS";
import newsletterCS from "./../modules/model/newsletterCS";
import newsletterDS from "./../modules/model/newsletterDS";
import newsletterES from "./../modules/model/newsletterES";
import newsletterFS from "./../modules/model/newsletterFS";
import newsletterGS from "./../modules/model/newsletterGS";
import newsletterHS from "./../modules/model/newsletterHS";
import newsletterIS from "./../modules/model/newsletterIS";
import newsletterJS from "./../modules/model/newsletterJS";
import newsletterKS from "./../modules/model/newsletterKS";
import newsletterLS from "./../modules/model/newsletterLS";
import newsletterMS from "./../modules/model/newsletterMS";
import newsletterNS from "./../modules/model/newsletterNS";
import portfolioAS from "./../modules/model/portfolioAS";
import portfolioBS from "./../modules/model/portfolioBS";
import portfolioCS from "./../modules/model/portfolioCS";
import portfolioDS from "./../modules/model/portfolioDS";
import portfolioES from "./../modules/model/portfolioES";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import print from "print-js";

export default {
  components: {
    newsletterAS,
    newsletterBS,
    newsletterCS,
    newsletterDS,
    newsletterES,
    newsletterFS,
    newsletterGS,
    newsletterHS,
    newsletterIS,
    newsletterJS,
    newsletterKS,
    newsletterLS,
    newsletterMS,
    newsletterNS,
    portfolioAS,
    portfolioBS,
    portfolioCS,
    portfolioDS,
    portfolioES,
  },
  data() {
    return {
      pdfurl: "",
      loading: false,
      type: "",
      list: [],
      title: "",
      status: "",
      filePath: "",
      viewCarouselIndex: 1,
      height: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.list = JSON.parse(sessionStorage.getItem("journalPageData"));
    this.title = sessionStorage.getItem("journalPageDataTit");
    this.status = sessionStorage.getItem("journalStatus");
    this.filePath = sessionStorage.getItem("journalFilePath");
    // for(let i=0;i<this.list.length;i++) {
    //   this.list[i].data.edit = this.HTMLEncode(this.list[i].data.edit)
    // }
    this.type = this.$route.query.type || "";
  },
  mounted() {
    window.onresize = () => {
      this.getHeight();
    };
    this.getHeight();
  },
  methods: {
    htmlDecode,

    hasPermission,

    HTMLEncode(html) {
      var temp = document.createElement("div");
      temp.textContent != null
        ? (temp.textContent = html)
        : (temp.innerText = html);
      var output = temp.innerHTML;
      temp = null;
      return output;
    },

    carouselChange(e) {
      this.viewCarouselIndex = e + 1;
    },

    getHeight() {
      const h = document.documentElement.clientHeight;
      this.height = h;
    },
    zoom() {
      let pages = this.$refs.page;
      for (let idx = 0; idx < pages.length; idx++) {
        pages[idx].style.transform = "scale(2)";
      }
    },

    async createPdf(type, x) {
      this.loading = true;
      if (
        (this.status === "PENDING" || this.status === "POSTED") &&
        !this.$route.query.rebuild
      ) {
        if (type === "download") {
          window.open(
            `${process.env.VUE_APP_fileBasePath}${this.filePath}`,
            "_blank"
          );
        } else if (type === "print") {
          printJS({
            printable: `${process.env.VUE_APP_fileBasePath}${this.filePath}`,
            type: "pdf",
          });
        }
        this.loading = false;
      } else {
        let pages = this.$refs.page;

        let toImages = [];
        let pdf;
        let scale = 3;
        let time = 0;
        let ut = navigator.userAgent;
        for (let idx = 0; idx < pages.length; idx++) {
          // if (
          //   /Safari/.test(navigator.userAgent) &&
          //   !/Chrome/.test(navigator.userAgent)
          // ) {
          //   time = time + 16000;
          //   console.log(222);
          // }
          // setTimeout(() => {
          console.log(idx);

          // let width = pages[idx].getBoundingClientRect().width * scale;
          // let height = pages[idx].getBoundingClientRect().height * scale;
          // toImages.push(domtoimage.toJpeg(pages[idx]))
          let imageInfo = await domtoimage.toJpeg(pages[idx], {
            httpTimeout: 600000,
            quality: 0.95,
            width: pages[idx].getBoundingClientRect().width * scale,
            height: pages[idx].getBoundingClientRect().height * scale,
            style: {
              margin: 0,
              transform: "scale(" + scale + ")",
              // transform: 'scale(1)',
              transformOrigin: "top left",
              width: pages[idx].getBoundingClientRect().width * scale,
              height: pages[idx].getBoundingClientRect().height * scale,
            },
          });
          toImages.push(imageInfo);
          // }, time);
        }
        console.log("ok----", toImages);
        // setTimeout(() => {
        Promise.allSettled(toImages).then((arr) => {
          console.log(arr);
          for (let idx = 0; idx < arr.length; idx++) {
            let width = pages[idx].getBoundingClientRect().width * scale;
            let height = pages[idx].getBoundingClientRect().height * scale;
            console.log(width);
            console.log(height);
            if (pdf == null) {
              pdf = new jsPDF({
                orientation: width > height ? "l" : "p",
                unit: "mm",
                format: width > height ? [297, 210] : [210, 297],
              });
            }
            pdf.setPage(idx + 1);
            let img = arr[idx].value;
            pdf.addImage(
              img,
              "JPEG",
              5,
              5,
              width > height ? 297 - 10 : 210 - 10,
              width > height ? 210 - 10 : 297 - 10
            );
            if (idx + 1 < pages.length) {
              pdf.addPage({
                orientation: width > height ? "l" : "p",
                unit: "mm",
                format: [width, height],
              });
            }
          }
          if (type === "download") {
            pdf.save(this.title + ".pdf");
          } else if (type === "print") {
            this.pdfurl = pdf.output("bloburl");
            printJS({
              printable: this.pdfurl,
              type: "pdf",
            });
          }
          this.loading = false;
        });
        // }, time);
      }

      // domtoimage.toJpeg(pages[idx], options).then(
      //       function (pageData) {
      //         // let canvas = await html1canvas(pages[idx], {
      //         //       backgroundColor: "transparent",
      //         //       allowTaint: true,
      //         //       useCORS: true,
      //         //       scale: x
      //         //   });
      //         //
      //         //   let pageData = canvas.toDataURL('image/jpeg', 0.0)
      //         let width = pages[idx].clientWidth * 2
      //         let height = pages[idx].clientHeight * 2
      //         console.log(width)
      //         console.log(height)
      //         if (pdf == null) {
      //           pdf = new jsPDF({
      //             orientation: width > height ? "l" : "p",
      //             unit: "pt",
      //             format: [width, height]
      //           })
      //         }
      //         pdf.setPage(idx + 0)
      //         pdf.addImage(pageData, 'JPEG', -1, 0, width, height)
      //         if (idx + 0 < pages.length) {
      //           pdf.addPage({
      //             orientation: width > height ? "l" : "p",
      //             unit: "pt",
      //             format: [width, height]
      //           })
      //         }
      //     })
      //
      //   if (type === 'download') {
      //     pdf.save(this.title + ".pdf")
      //   } else if (type === 'print') {
      //     this.pdfurl = pdf.output('bloburl')
      //     printJS({
      //       printable: this.pdfurl,
      //       type: 'pdf'
      //     })
      //   }
      //   this.loading = false
    },

    // var buffer = pdf.output("datauristring")
    // // 将base64格式的字符串转换为file文件
    // var myfile = this.dataURLtoFile(buffer, moment().valueOf() + 'files.pdf')

    // var formData = new FormData()
    // formData.append("file", myfile)
    // formData.append("multipartFile", myfile)
    // this.uploadFile(formData)

    uploadFile(formData) {
      if (formData) {
        Ajax.post("/media/public/file/uploadExt", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            // if (res.code === "0000") {
            //   this.cover = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
            // }
            // if (res.code === "9999") {
            //   this.handleSnack("Maximum upload size exceeded")
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      //转换成file对象
      return new File([u8arr], filename, { type: mime });

      //转换成成blob对象
      // return new Blob([u8arr],{type:mime});
    },
  },
};
</script>
<style lang="less" scoped>
.view-carousel-inner {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: #f3f5f7;

  .indicator {
    position: absolute;
    left: 50%;
    bottom: 20px;
    margin-left: -54px;
    width: 108px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background: #c2c4c6;
    color: #fff;
    font-size: 14px;
    font-family: Roboto;
    text-align: center;

    .s {
      margin-right: 5px;
    }

    .t {
      margin: 0 5px;
    }
  }

  &.newsletter {
    .journal-page {
      //margin: -333px 0 0 -235px !important;
      width: 470px !important;
      height: 666px !important;
      margin-top: calc(50vh - 330px);
      margin-left: calc(50vw - 235px);
    }
  }
  &.portfolio {
    .journal-page {
      //margin: -235px 0 0 -330px !important;
      width: 660px !important;
      height: 470px !important;
      margin-top: calc(50vh - 235px);
      margin-left: calc(50vw - 330px);
    }
  }
  .journal-page {
    margin-top: 0;
    padding: 0;
    //margin-left: calc(50vw - 330px);
    //margin-top:calc(52vh - 235px);
    //margin:50% !important;
    //width: 470px;
    //height: 666px;
    background: #fff;
  }
}

.tool {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: #fff;
  text-align: right;

  .col-btn {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    vertical-align: middle;
    cursor: pointer;

    &.print {
      .col-tit {
        margin-left: -19px;
        width: 38px;
      }
    }

    .col-tit {
      display: none;
      position: absolute;
      left: 50%;
      bottom: -10px;
      margin-left: -32px;
      width: 64px;
      height: 20px;
      line-height: 20px;
      border-radius: 4px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      background: #606367;
    }

    &:hover {
      .col-tit {
        display: block;
      }
    }
  }
}
</style>
