<!-- userRoles -->
<template>
  <GgPage pageType="2" title="Roles">
    <template v-slot:left>
      <div class="mar-l-b">
        <gg-create-button
          @click="openFormDialog('add', '')"
          label="Create role"
        />
      </div>
      <gg-flex-menus
        :menus="menuDatas"
        @select="menuSelect"
        :default-active="activeIndex"
      />
    </template>
    <edoovo-table
      height="100%"
      :ref="usersData.ref"
      :data="usersData.tableData"
      rowKey="id"
      title="Roles"
      defaultValue="-"
      class="list-tableBox"
      :sort="usersData.sort"
      v-loading="usersData.isLoading"
      @sortChange="_onSortChange"
    >
      <!-- ===== -->
      <!-- 空状态 -->
      <!-- ===== -->
      <div slot="empty" style="text-align: center">
        <div
          style="
            width: 213px;
            text-align: center;
            border-radius: 50%;
            margin-bottom: 12px;
            line-height: 200px;
          "
        >
          <img
            style="width: 100%"
            :src="require('@/assets/images/products/noData.svg')"
          />
        </div>
        <p style="font-size: 22px">No Roles yet.</p>
      </div>
      <!-- ======= -->
      <!-- 分页组件 -->
      <!-- ======= -->
      <edoovo-pagination
        slot="pagination"
        @size-change="_pageSizeChange"
        @current-change="_pageChange"
        :current-page="query.current"
        :page-size="query.size"
        :total="usersData.totalNum"
      />
      <!-- ====== -->
      <!-- 筛选组件 -->
      <!-- ====== -->
      <div v-if="filterRenderData.length" class="list-filterBox" slot="filter">
        <base-filter
          ref="tablefilter"
          @filterCallback="_filterCallback"
          :renderData="filterRenderData"
          :multKeys="multKeys"
        />
      </div>
      <!-- =========== -->
      <!-- 每一项column -->
      <!-- =========== -->
      <edoovo-table-column
        size="L"
        label="Role name"
        class="id table-cell-box"
        prop="name"
        sortName="name"
        :tooltip="true"
        @click="openViewDialog"
      />
      <edoovo-table-column
        size="XL"
        label="Description"
        prop="describ"
        sortName="describ"
        :tooltip="true"
      />
      <!-- ====== -->
      <!-- action -->
      <!-- ====== -->
      <edoovo-table-column v-slot="{ row }" key="action">
        <el-tooltip
          content="Edit a role"
          popper-class="edoovo-table-cell-tooltip"
          :visible-arrow="false"
        >
          <gg-submit-button @click="openFormDialog('edit', row)">
            Edit
          </gg-submit-button>
        </el-tooltip>
        <el-tooltip
          content="Duplicate a role"
          popper-class="edoovo-table-cell-tooltip"
          :visible-arrow="false"
        >
          <gg-submit-button @click="openFormDialog('duplicate', row)">
            Duplicate
          </gg-submit-button>
        </el-tooltip>
        <el-tooltip
          content="Delete a role"
          popper-class="edoovo-table-cell-tooltip"
          :visible-arrow="false"
        >
          <div style="margin-left: 10px">
            <gg-submit-button @click="handleDelete(row.id)">
              Delete
            </gg-submit-button>
          </div>
        </el-tooltip>
      </edoovo-table-column>
    </edoovo-table>

    <!-- Create new role -->
    <BaseFormDialog1
      v-model="formDialogAdd.visible"
      v-if="formDialogAdd.visible"
      :title="formDialogAdd.title"
      :data="formDialogAdd.dataList"
      :renderPass="formDialogAdd.renderPass"
      @submit="handleAddForm"
      textReminder="* indicates a required field"
      userReminder="Minimum 1 app must be selected"
      cancelBtnTxt="Cancel"
      :submitBtnTxt="formDialogAdd.submitBtnTxt"
    >
      <template slot="renderHtml" slot-scope="scope">
        <gg-input
          v-if="scope.data.key == 'name'"
          :ref="scope.data.key"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          :error="nameErrorObj"
        />
        <div v-else-if="scope.data.key == 'describ'">
          <gg-input
            :key="scope.data.key"
            :ref="scope.data.key"
            v-model="scope.data.value"
            filedType="textarea"
            :error="describErrorObj"
            :placeholder="scope.data.placeholder"
          />
          <edoovo-table
            height="auto"
            ref="appAccessTable"
            :data="scope.data.tableData"
            rowKey="id"
            defaultValue="-"
            class="list-tableBox mar-t-20 mar-b-20"
          >
            <edoovo-table-column
              size="L"
              label="Apps"
              class="user_name"
              prop="moduleName"
              :tooltip="false"
              v-slot="{ row }"
            >
              <div>
                <base-img class="table-cell-img" :src="row.avatar" />
                <span class="table-cell-name">{{ row.moduleName }}</span>
              </div>
            </edoovo-table-column>
            <edoovo-table-column
              size="M"
              label="Status"
              prop="status"
              :tooltip="false"
              v-slot="{ row }"
            >
              <gg-switch
                style="margin-right: 8px"
                v-model="row.status"
                active-color="#1A73E8"
                inactive-color="#B4B4B4"
              />
              <span v-if="formDialogAdd.title !== formDialogAdd.add">
                <span v-if="row.status">On</span>
                <span v-else>Off</span>
              </span>
            </edoovo-table-column>
          </edoovo-table>
        </div>
      </template>
    </BaseFormDialog1>
    <!-- View role -->
    <gg-Dialog
      width="580px"
      :visible.sync="formDialogView.visible"
      :show-close="true"
      :before-close="
        () => {
          formDialogView.visible = !formDialogView.visible;
        }
      "
    >
      <div slot="title">
        View role
        <i
          class="title-icon iconfont iconedit icon-edit-r"
          @click="openFormDialog('edit', formDialogView.viewData)"
        ></i>
      </div>
      <div>
        <ul class="role-cintent">
          <li>
            <div class="role-cintent-left">Role name</div>
            <div class="role-cintent-right">
              {{ formDialogView.viewData.name }}
            </div>
          </li>
          <li>
            <div class="role-cintent-left">Description</div>
            <div class="role-cintent-right">
              {{ formDialogView.viewData.describ }}
            </div>
          </li>
        </ul>
        <edoovo-table
          height="auto"
          ref="appAccessTable"
          :data="formDialogView.data"
          rowKey="id"
          defaultValue="-"
          class="list-tableBox"
        >
          <edoovo-table-column
            size="M"
            label="Apps"
            class="user_name"
            prop="moduleName"
            :tooltip="true"
            v-slot="{ row }"
          >
            <div>
              <head-img
                class="table-cell-img"
                :src="row.avatar ? `${row.avatar}` : ``"
                line-height="24"
              />
              <span class="table-cell-name">{{ row.moduleName }}</span>
            </div>
          </edoovo-table-column>
          <edoovo-table-column size="S" label="Status" prop="status" />
        </edoovo-table>
      </div>
    </gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </GgPage>
</template>

<script>
import { Ajax } from "@/common";
import { menuIconParams } from "@/common/baseData";
export default {
  components: {},
  data() {
    return {
      query: {
        size: 10,
        current: 1,
      },
      menuDatas: [
        {
          name: "Users",
          key: "Users",
          children: [
            {
              name: "Active",
              key: "Active",
            },
            {
              name: "Deleted",
              key: "Deleted",
            },
          ],
        },
        {
          name: "Roles",
          key: "Roles",
          active: true,
        },
      ],
      activeIndex: "Roles",
      usersData: {
        ref: "usersTable",
        isLoading: false,
        totalNum: 4,
        sort: { sortName: "name", sortType: "desc" },
        tableData: [],
      },
      filterRenderData: [
        // { filterKey: "email", type: "input", title: "email" },
        { filterKey: "name", type: "input", title: "Role name" },
        { filterKey: "describ", type: "input", title: "Description" },
      ],
      multKeys: [],
      formDialogAdd: {
        visible: false,
        title: "",
        add: "Create new role",
        duplicate: "Duplicate role",
        edit: "Edit role",
        submitBtnTxt: "Create",
        renderPass: false,
        dataList: [
          {
            placeholder: "Role name *",
            key: "name",
            value: "",
            renderHtml: true,
          },
          {
            placeholder: "Description",
            key: "describ",
            value: "",
            tableData: [
              {
                moduleName: "Companies",
                avatar: require("@/assets/images/menu/companies.svg"),
                status: false,
              },
              {
                moduleName: "Parents",
                avatar: require("@/assets/images/menu/parents.svg"),
                status: false,
              },
            ],
            renderHtml: true,
          },
        ],
      },
      formDialogView: {
        visible: false,
        viewData: {},
        data: [
          {
            moduleName: "Companies",
            avatar: require("@/assets/images/menu/companies.svg"),
            status: "Off",
          },
          {
            moduleName: "Parents",
            avatar: require("@/assets/images/menu/parents.svg"),
            status: "Off",
          },
        ],
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  computed: {
    nameErrorObj() {
      let nameVal = this.formDialogAdd.dataList.find(
        (item) => item.key === "name"
      ).value;
      if (nameVal === "") {
        return {
          show: true,
          message: "name required",
        };
      }

      let isPass = nameVal.length < 21;
      return {
        message: "Character limit of 20",
        show: !isPass,
      };
    },
    describErrorObj() {
      let describVal = this.formDialogAdd.dataList.find(
        (item) => item.key === "describ"
      ).value;
      let isPass = describVal.length < 41;
      return {
        message: "Character limit of 40",
        show: !isPass,
      };
    },
  },
  watch: {
    formDialogAdd: {
      deep: true,
      handler: function (res) {
        this.$nextTick(() => {
          let appAccessPass = res.dataList
            .find((i) => i.key == "describ")
            .tableData.some((item) => item.status);
          let namePass =
            res.dataList.find((item) => item.key === "name").value !== "" &&
            !this.$refs.name.error.show;
          let describPass = !this.$refs.describ.error.show;
          res.renderPass = appAccessPass && namePass && describPass;
        });
      },
    },
  },
  methods: {
    _getSvg(menu) {
      if (menuIconParams[menu] && menuIconParams[menu].svg) {
        return menuIconParams[menu].svg;
      }
      return "product_app";
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      if (key !== "Roles") {
        this.$router.push({ name: "users" });
      }
    },
    getData() {
      this.usersData.isLoading = true;
      let params = Object.assign({}, this.query, {
        userId: this.$store.state.user.userId,
      });

      Ajax.post("/usermanage/role/pageMoreList", params)
        .then((res) => {
          this.usersData.isLoading = false;
          let { records, total } = res.data;
          this.usersData.tableData = records;
          this.usersData.totalNum = total;
        })
        .catch((err) => {
          this.usersData.isLoading = false;
        });
    },
    _onSortChange(sort) {
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      this.getData();
    },
    _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
    _filterCallback(data) {
      let obj = {};
      if (data.length == 0) {
        this.query = {
          size: 10,
          current: 1,
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });

      this.query = {
        size: 20,
        current: 1,
      };
      this.query = Object.assign(obj, this.query);
      this.getData();
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
    async openFormDialog(type, row) {
      // 通过参数查询role及权限
      let rolePermission = await Ajax.get("/usermanage/role/selectRoleById", {
        roleId: row.id,
      }).then((res) => {
        if (res.code === "0000") {
          return res.data;
        }
      });

      switch (type) {
        case "add":
          this.formDialogAdd.title = this.formDialogAdd.add;
          this.formDialogAdd.submitBtnTxt = "Create";
          this.formDialogAdd.dataList.forEach((i) => {
            i.value = "";
            if (i.tableData) {
              i.tableData.map((j) => (j.status = false));
            }
          });
          break;
        case "duplicate":
          this.formDialogAdd.title = this.formDialogAdd.duplicate;
          this.formDialogAdd.submitBtnTxt = "Duplicate";
          this.formDialogAdd.id = row.id;
          this.formDialogAdd.dataList.forEach((i) => {
            i.value = row[i.key];
            if (i.key === "name") {
              i.value = `Copy of ${row[i.key]}`;
            }

            // todo
            if (i.tableData) {
              i.tableData.map((j) => (j.status = false));
              rolePermission.forEach((role) => {
                i.tableData.forEach((item) => {
                  if (role.moduleName === item.moduleName) {
                    item.status = true;
                  }
                });
              });
            }
          });
          break;
        case "edit":
          this.formDialogAdd.title = this.formDialogAdd.edit;
          this.formDialogAdd.submitBtnTxt = "Save";
          this.formDialogAdd.id = row.id;

          this.formDialogAdd.dataList.forEach((i) => {
            i.value = row[i.key];
            if (i.tableData) {
              i.tableData.map((j) => (j.status = false));
              rolePermission.forEach((role) => {
                i.tableData.forEach((item) => {
                  if (role.moduleName === item.moduleName) {
                    item.status = true;
                  }
                });
              });
            }
          });
          break;
      }
      this.formDialogAdd.visible = true;
      this.formDialogView.visible = false;
    },
    handleAddForm() {
      if (this.formDialogAdd.title === this.formDialogAdd.edit) {
        this.handleEditSubmit();
      } else if (this.formDialogAdd.title === this.formDialogAdd.add) {
        this.handleAddSubmit();
      } else {
        this.handleDuplicateSubmit();
      }
    },
    fillData() {
      let formData = {};

      this.formDialogAdd.dataList.forEach((i) => {
        formData[i.key] = i.value;
        formData.userId = this.$store.state.user.userId;
        formData.rolePermissionList = [];
        if (i.tableData) {
          let rolePermissionList = i.tableData.filter((access) => {
            return access.status;
          });

          rolePermissionList.forEach((role) => {
            let obj = {
              hasModule: "1",
              moduleName: role.moduleName,
              //permissions: "on",
            };

            formData.rolePermissionList.push(obj);
          });
        }
      });

      return formData;
    },
    handleAddSubmit() {
      Ajax.post("/usermanage/role/save", this.fillData()).then((res) => {
        if (res.code === "0000") {
          this.getData();
          this.formDialogAdd.visible = false;
          this.handleSnack("Role has been created");
        } else {
          this.handleSnack(res.message);
        }
      });
    },
    handleEditSubmit() {
      let params = {};
      params = Object.assign({}, this.fillData(), {
        id: this.formDialogAdd.id,
      });

      Ajax.post("/usermanage/role/update", params).then((res) => {
        this.getData();
        this.formDialogAdd.visible = false;
      });

      this.handleSnack("Role has been edited");
    },
    handleDuplicateSubmit() {
      let params = {};
      params = Object.assign({}, this.fillData(), {
        id: this.formDialogAdd.id,
      });

      Ajax.post("/usermanage/role/duplicate", params).then((res) => {
        this.getData();
      });

      this.handleSnack("Role has been duplicated");
    },
    async openViewDialog(row) {
      this.formDialogView.viewData = Object.assign({}, row.row);
      this.formDialogView.visible = true;

      let rolePermission = await Ajax.get("/usermanage/role/selectRoleById", {
        roleId: row.row.id,
      }).then((res) => {
        if (res.code === "0000") {
          return res.data;
        }
      });

      this.formDialogView.data.map((j) => (j.status = "Off"));
      rolePermission.forEach((role) => {
        this.formDialogView.data
          .filter((table) => table.moduleName === role.moduleName)
          .map((v) => {
            v.status = "On";
          });
      });
    },
    handleDelete(id) {
      this.$confirm(
        "Once the role is deleted, you will not be able to recover it.",
        "Delete role ?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.get("/usermanage/role/delete", { id: id }).then((res) => {
            if (res.code === "0000") {
              this.getData();
              this.snackbarData = {
                visible: true,
                content: "Role has been deleted",
              };
            }
          });
        })
        .catch(() => {});
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.table-cell-img {
  width: 24px;
  height: 24px;
  /deep/ img {
    height: 100%;
    width: auto;
  }
}
.user_avatar[data-v-f64cc43c] {
  background-color: #ffffff;
}
</style>
