<!-- students -->
<template>
  <div class="students">
    <GgPage
      pageType="2"
      title="Students"
      topType="location"
      @changeTopSelect="changeTopSelect"
    >
      <template v-slot:left>
        <div
          class="createBtnBox"
          v-if="
            $buttonPermission('Students', 'Full') ||
            $buttonPermission('Students', 'Edit') ||
            $buttonPermission('Students', 'Create')
          "
        >
          <gg-create-button @click="enrollStudent" label="Enroll student" />
        </div>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <div style="height: 100%">
        <edoovo-table
          ref="multipleTable"
          :data="tableData"
          :title="tableTitle"
          tooltip-effect="dark"
          default-value="-"
          rowKey="`${id}${createTime}`"
          v-loading="loading"
          :sort="sort"
          @sortChange="_onSortChange"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No student yet.</p>
          </div>
          <!-- ====== -->
          <!-- 筛选组件 -->
          <!-- ====== -->
          <div
            v-if="filterRenderData.length"
            class="list-filterBox"
            slot="filter"
          >
            <BaseFilter
              @filterCallback="_filterCallback"
              :renderData="filterRenderData"
              :multKeys="[]"
            />
          </div>
          <!-- ======= -->
          <!-- 分页组件 -->
          <!-- ======= -->
          <edoovo-pagination
            slot="pagination"
            @size-change="_pageSizeChange"
            @current-change="_pageChange"
            :current-page="query.current"
            :page-size="query.size"
            :total="totalNum"
          />
          <edoovo-table-column
            size="L"
            label="Name"
            prop="firstName"
            sortName="firstName"
            :tooltip="true"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.firstName} ${row.lastName}`"
          >
            <div
              class="table-cell-box"
              @click="linkTo(row)"
              style="display: flex; align-items: center"
            >
              <head-img
                class="table-cell-img"
                :src="row.photo ? `${row.photo}` : ``"
                :firstName="row.firstName"
                :lastName="row.lastName"
                line-height="32"
              />
              <span
                class="table-cell-name"
                style="
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  display: block;
                "
                >{{ row.firstName }} {{ row.lastName }}</span
              >
            </div>
          </edoovo-table-column>
          <edoovo-table-column size="M" label="Student ID" prop="studentId" />
          <edoovo-table-column
            size="M"
            label="Gender"
            prop="gender"
            sortName="gender"
            :tooltip="true"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.gender ? row.gender : '-'}`"
            >{{ row.gender ? row.gender : "-" }}
          </edoovo-table-column>
          <edoovo-table-column
            size="M"
            label="Date of birth"
            prop="dateOfBirth"
            sortName="dateOfBirth"
            v-slot="{ row }"
            :getTooltipText="(row) => `${formDate(row.dateOfBirth)}`"
          >
            {{ formDate(row.dateOfBirth) }}
          </edoovo-table-column>
          <edoovo-table-column size="S" label="Age" prop="age" />
          <edoovo-table-column
            size="M"
            label="Class"
            prop="className"
            sortName="className"
          />
          <edoovo-table-column
            size="M"
            label="Contacts"
            prop="contacts"
            v-slot="{ row }"
            class="table-cell-box"
          >
            <div @click="openContactsDialog(row)">
              {{ row.contacts || "-" }}
            </div>
          </edoovo-table-column>
          <edoovo-table-column
            size="M"
            label="Location"
            prop="locationName"
            sortName="locationName"
            v-if="query.status != 'Withdrawn'"
          />

          <edoovo-table-column size="M" key="action" v-slot="{ row }">
            <el-tooltip
              popper-class="edoovo-table-cell-tooltip"
              content="Edit student"
              :visible-arrow="false"
              v-if="
                $buttonPermission('Students', 'Full') ||
                $buttonPermission('Students', 'Edit')
              "
            >
              <!-- 	<baseButton type="primary" v-if="activeIndex != 'Withdrawn'" @click="editStudent(row)">Edit
							</baseButton> -->
              <gg-submit-button
                v-if="activeIndex != 'Withdrawn'"
                @click="editStudent(row)"
              >
                Edit
              </gg-submit-button>
            </el-tooltip>

            <template
              v-if="
                dropdownData.length < 3 && $buttonPermission('Students', 'Full')
              "
            >
              <el-tooltip
                popper-class="edoovo-table-cell-tooltip"
                :content="
                  row.contacts > 0 && dropItem == 'Delete'
                    ? 'Student with connected family cannot be deleted'
                    : `${dropItem} student`
                "
                :visible-arrow="false"
                v-for="(dropItem, index) in dropdownData"
                :key="index"
              >
                <!-- <baseButton type="primary" @click="handleStatus(row, dropItem)"
									:disabled="row.contacts > 0 && dropItem == 'Delete'"
									
									>
									{{ dropItem }}
								</baseButton> -->
                <gg-submit-button
                  type="primary"
                  @click="handleStatus(row, dropItem)"
                  :disabled="row.contacts > 0 && dropItem == 'Delete'"
                >
                  {{ dropItem }}
                </gg-submit-button>
              </el-tooltip>
            </template>
            <gg-dropdown
              class="dropdown-con"
              v-if="
                dropdownData.length >= 3 &&
                $buttonPermission('Students', 'Full')
              "
              style="font-size: OpenSans-Bold !important"
            >
              More
              <template slot="menus">
                <gg-dropdown-item
                  v-for="(dropItem, index) in dropdownData"
                  :key="index"
                  :visible-arrow="false"
                  @click="handleStatus(row, dropItem)"
                  :disabled="row.contacts > 0 && dropItem == 'Delete'"
                  :title="
                    row.contacts > 0 && dropItem == 'Delete'
                      ? 'Student with connected family cannot be deleted'
                      : ''
                  "
                  v-if="!(row.contacts > 0 && dropItem == 'Delete')"
                >
                  <!-- <hr v-if="" style="height: 1px; width: 100%; padding: 0; background: #e9e9e9; margin: 0" /> -->
                  {{ dropItem }}
                </gg-dropdown-item>
                <el-tooltip
                  popper-class="edoovo-table-cell-tooltip"
                  :content="`Student with connected family cannot be deleted`"
                  :visible-arrow="false"
                  v-else
                >
                  <gg-dropdown-item :visible-arrow="false" :disabled="true">
                    <!-- <hr v-if="" style="height: 1px; width: 100%; padding: 0; background: #e9e9e9; margin: 0" /> -->
                    Delete
                  </gg-dropdown-item>
                </el-tooltip>
              </template>
            </gg-dropdown>
          </edoovo-table-column>
        </edoovo-table>
      </div>
    </GgPage>
    <!-- enroll student -->
    <BaseFormDialog1
      v-if="formDialogVisible"
      v-model="formDialogVisible"
      :title="formDialogTitle"
      :data="formDataList"
      @submit="handleSubmit"
      :renderPass="renderPass"
      :submitBtnTxt="formDialogBtnTxt"
      textReminder="* indicates a required field"
      ref="baseForm"
    >
      <template slot="renderHtml" slot-scope="scope">
        <gg-input
          :disabled="isStudentIdEdit"
          :class="[{ float: scope.data.float }, scope.data.className]"
          :key="scope.data.key"
          :ref="scope.data.key"
          v-model="scope.data.value"
          :placeholder="scope.data.placeholder"
          :error="errorObj"
          :autofocus="scope.data.autofocus"
          v-if="scope.data.key === 'studentId'"
        />

        <div
          class="checkBoxCon"
          v-if="
            (scope.data.key === 'auto' && isShowAuto) ||
            (scope.data.key === 'auto' && formDialogBtnTxt == 'Save')
          "
        >
          <md-checkbox
            v-model="scope.data.value"
            true-value="1"
            false-value="0"
            class="md-primary"
            @change="changeAuto"
            >Auto</md-checkbox
          >
          <el-tooltip
            :visible-arrow="false"
            placement="bottom"
            effect="light"
            popper-class="text"
            content="Use student ID auto generated by the system"
          >
            <div class="prompt">
              <img :src="require('@/assets/images/prompt.png')" />
            </div>
          </el-tooltip>

          <!-- <p class="text"></p> -->
        </div>
        <baseGGSelect
          v-if="
            scope.data.key === 'schoolId' &&
            (!scope.data.inClass || formDialogBtnTxt == 'Enroll')
          "
          v-model="scope.data.value"
          placeholder="Location *"
          ref="schoolId"
          class="float"
        >
          <md-option
            v-for="(item, index) in locationList"
            :key="index"
            :value="item.id"
          >
            {{ item.value }}
          </md-option>
        </baseGGSelect>
        <el-tooltip
          :visible-arrow="false"
          placement="bottom"
          popper-class="text"
          content="Cannot edit location if the student is assigned to a class"
          v-else
        >
          <!-- 	<baseGGSelect v-if="scope.data.key === 'schoolId'" v-model="scope.data.value"
						placeholder="Location *" ref="schoolId" class="float" :disabled='true'>
						<md-option v-for="(item, index) in locationList" :key="index" :value="item.id">
							{{ item.value }}
						</md-option>
					</baseGGSelect> -->

          <div
            v-if="scope.data.key === 'schoolId'"
            class="md-field gg_select el-tooltip float md-theme-default md-disabled md-has-value spec"
            aria-describedby="el-tooltip-4959"
            tabindex="0"
            style="line-height: 2"
          >
            <label data-v-513b0464="" for="undefined">Location *</label>
            <span
              v-if="scope.data.key === 'schoolId'"
              ref="schoolId"
              class="float"
              :disabled="true"
            >
              <span
                v-for="(item, index) in locationList"
                v-if="item.id == scope.data.value"
                style="white-space: nowrap"
              >
                {{ item.value }}
              </span>
            </span>
          </div>
        </el-tooltip>
      </template>
    </BaseFormDialog1>
    <!-- contacts number dialog -->
    <gg-dialog
      :visible.sync="contacts.visible"
      title="Contacts"
      :showClose="true"
    >
      <div class="contactsDialog" v-loading="contacts.loading">
        <div class="title">Family</div>
        <edoovo-table
          height="300px"
          ref="multipleTable"
          :data="contacts.family.dataList"
          rowKey="id"
          defaultValue="-"
          class="list-tableBox"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%; height: 100px"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No staff yet.</p>
          </div>
          <!-- =========== -->
          <!-- 每一项column -->
          <!-- =========== -->

          <edoovo-table-column
            size="L"
            label="Name"
            prop="name"
            :tooltip="true"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.firstName} ${row.lastName}`"
          >
            <div>
              <head-img
                class="table-cell-img"
                :src="row.photo ? `${row.photo}` : ``"
                :firstName="row.firstName"
                :lastName="row.lastName"
                line-height="32"
              />
              <span class="table-cell-name"
                >{{ row.firstName }} {{ row.lastName }}</span
              >
            </div>
          </edoovo-table-column>
          <edoovo-table-column
            size="M"
            label="Relationship"
            prop="releationship"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.releationship}`"
          >
            {{ row.releationship }}
          </edoovo-table-column>
          <edoovo-table-column size="L" label="Email" prop="email" />
          <edoovo-table-column
            size="M"
            label="Phone"
            prop="phoneNumber"
            :getTooltipText="
              (row) =>
                `${row.countryCode ? row.countryCode : ''} ${
                  row.phoneNumber ? row.phoneNumber : '-'
                }`
            "
            v-slot="{ row }"
          >
            {{ row.countryCode }} {{ row.phoneNumber || "-" }}
          </edoovo-table-column>
          <edoovo-table-column
            size="M"
            label="Status"
            prop="parentStatus"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.parentStatus}`"
          >
            {{ row.parentStatus }}
            <!--=='Connected' ||row.parentStatus=='Disconnected'?'Connected':'Pending' -->
          </edoovo-table-column>
        </edoovo-table>
        <div class="title" v-if="contacts.pickup.dataList.length != 0">
          Pickup
        </div>
        <edoovo-table
          max-height="300px"
          ref="multipleTable"
          :data="contacts.pickup.dataList"
          rowKey="id"
          defaultValue="-"
          class="list-tableBox"
          v-if="contacts.pickup.dataList.length != 0"
        >
          <!-- ===== -->
          <!-- 空状态 -->
          <!-- ===== -->
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 100px;
              "
            >
              <img
                style="width: 100%; height: 100px"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No staff yet.</p>
          </div>
          <!-- =========== -->
          <!-- 每一项column -->
          <!-- =========== -->
          <edoovo-table-column
            size="L"
            label="Name"
            prop="name"
            :tooltip="true"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.firstName} ${row.lastName}`"
            @click="() => {}"
          >
            <div>
              <head-img
                class="table-cell-img"
                :src="row.photo ? `${row.photo}` : ``"
                :firstName="row.firstName"
                :lastName="row.lastName"
                line-height="32"
              />
              <span class="table-cell-name"
                >{{ row.firstName }} {{ row.lastName }}</span
              >
            </div>
          </edoovo-table-column>
          <edoovo-table-column
            size="M"
            label="Relationship"
            prop="releationship"
            v-slot="{ row }"
            :getTooltipText="(row) => `${row.releationship}`"
          >
            {{ row.releationship }}
          </edoovo-table-column>

          <edoovo-table-column
            size="M"
            label="Phone"
            prop="phoneNumber"
            :getTooltipText="
              (row) =>
                `${row.countryCode ? row.countryCode : ''} ${
                  row.phoneNumber ? row.phoneNumber : '-'
                }`
            "
            v-slot="{ row }"
          >
            {{ row.countryCode }}{{ row.phoneNumber || "-" }}
          </edoovo-table-column>
        </edoovo-table>
      </div>
    </gg-dialog>
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import moment from "moment";
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  name: "students",
  components: {},
  data() {
    return {
      menuDatas: [
        {
          name: "Enrolled",
          key: "Enrolled",
          active: true,
        },
        {
          name: "Deferred",
          key: "Deferred",
        },
        {
          name: "Withdrawn",
          key: "Withdrawn",
        },
      ],
      dropdownData: ["Defer", "Withdraw", "Delete"],
      activeIndex: "Enrolled",
      tableTitle: "Enrolled students",
      tableData: [],
      filterRenderData: [
        {
          title: "Name",
          filterKey: "firstName",
          type: "input",
        },
        {
          title: "Gender",
          filterKey: "gender",
          type: "radio",
          radioData: [
            {
              key: "Boy",
              value: "0",
            },
            {
              key: "Girl",
              value: "1",
            },
          ],
        },
        {
          title: "Date of birth",
          filterKey: "dateOfBirth",
          type: "date",
        },
        {
          title: "Class",
          filterKey: "className",
          type: "input",
        },
        {
          title: "Contacts",
          filterKey: "contact",
          type: "input",
        },
      ],
      loading: false,
      query: {
        size: 20,
        current: 1,
        locationId: "",
      },
      totalNum: 4,
      sort: {
        name: "",
        type: "",
      },
      snackbar: {
        isShow: false,
        content: "",
      },
      messageObj: {
        Delete: {
          title: "Delete student ?",
          desc: "Once the student is deleted, you will not be able to recover the student data.",
          cancelTxt: "Cancel",
          confirmTxt: "Delete",
          snackbarContent: "Student has been deleted",
        },
        Deferred: {
          title: "Defer student ?",
          desc: "Once deferred, this student will move to the deferred students. Student data will remain in the system.",
          cancelTxt: "Cancel",
          confirmTxt: "Defer",
          snackbarContent: "Student has been deferred",
        },
        Enrolled: {
          title: "Return student ?",
          desc: "Once returned, This student will move to the enrolled students.",
          cancelTxt: "Cancel",
          confirmTxt: "Return",
          snackbarContent: "Student has been enrolled",
        },
        Returned: {
          title: "Return student ?",
          desc: "Once returned, This student will move to the enrolled students.",
          cancelTxt: "Cancel",
          confirmTxt: "Return",
          snackbarContent: "Student has been returned",
        },
        Withdrawn: {
          title: "Withdraw student ?",
          desc: "Once Withdrawn, the student will be removed from assigned class. The student's family users will be disconnected with the system.   ",
          cancelTxt: "Cancel",
          confirmTxt: "Withdraw",
          snackbarContent: "Student has been withdrawn",
        },
      },
      formDialogVisible: false,
      formDataList: [
        {
          placeholder: "First name *",
          key: "firstName",
          value: "",
          autofocus: true,
          float: true,
          reg: /\S/,
          errorText: "First name is required",
        },
        {
          placeholder: "Last name *",
          key: "lastName",
          value: "",
          float: true,
          reg: /\S/,
          errorText: "Last name is required",
        },
        {
          placeholder: "Student ID *",
          key: "studentId",
          value: "",
          float: true,
          className: "",
          reg: /\S/,
          errorText: "Student ID is required",
          renderHtml: true,
        },
        {
          key: "auto",
          value: "1",
          float: true,
          renderHtml: true,
        },
        {
          placeholder: "Location *",
          key: "schoolId",
          value: null,
          renderHtml: true,
        },
      ],
      formDialogTitle: "",
      formDialogBtnTxt: "",
      selfStudentId: "",
      slotData: ["schoolId"],
      renderPass: true,
      studentId: "",
      dialogData: {}, // 填充数据
      contacts: {
        visible: false,
        loading: false,
        family: {
          isShow: false,
          dataList: [
            {
              name: 123,
              email: "sssjjs",
            },
          ],
        },
        pickup: {
          isShow: false,
          dataList: [
            {
              name: 123,
              relationShip: "sssjjs",
            },
          ],
        },
      },
      locationList: [],
      isAutoVisi: [],
      isShowAuto: false,
      isStudentIdEdit: false,
      errorObj: {},
      is_first: false,
      is_edit: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    formDataList: {
      handler() {
        this.$nextTick(() => {
          // if (!this.is_first) {
          // 	this.is_first = true
          // 	this.renderPass = false
          // 	return false
          // }
          // console.log(123)
          let renderPass = this.slotData.every(
            (item) =>
              (this.$refs[item] && !!this.$refs[item].value) ||
              this.formDialogBtnTxt == "Save"
          );
          // console.log(renderPass)
          this.renderPass = renderPass;
        });
      },
      deep: true,
    },
  },
  methods: {
    formDate(date) {
      let a;
      if (date) {
        a = moment.utc(date).local().format("DD/MM/yyyy");
      } else {
        a = "-";
      }
      return a;
    },
    computedAge(value, index) {
      // this.$nextTick(() => {
      let dateOfBirth = value;
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth() + 1;
      let birthYear = dateOfBirth && Number(dateOfBirth.split("/")[2]);
      let birthMonth = dateOfBirth && Number(dateOfBirth.split("/")[1]);
      let age = currentYear - birthYear;
      let monthTotal = age * 12 + currentMonth - birthMonth;
      if (dateOfBirth !== null) {
        this.tableData[index].age = `${parseInt(monthTotal / 12)}y ${
          monthTotal % 12
        }m`;
        // this.tableData.find((item) => item.key == "age").value =
      } else {
        this.tableData[index].age = "";
        // this.tableData.find((item) => item.key == "age").value = "";
      }
      // });
    },
    changeAuto(val) {
      if (val == 1) {
        Ajax.get("/usermanage/student/autoGenerateStudentId", {
          userId: this.user.userId,
        })
          .then((res) => {
            if (res.code != "0000") {
              this.handleSuspend(res.message);
            }
            this.formDataList.forEach((item) => {
              if (item.key == "studentId") {
                item.value = res.data.studentId;
                this.isStudentIdEdit = true;
              }
            });
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.isStudentIdEdit = false;
        this.formDataList.forEach((item) => {
          if (item.key == "studentId") {
            // item.value = ""
          }
        });
      }
    },
    menuSelect(key, item) {
      console.log(key, item);
      this.activeIndex = key;
      this.tableTitle = `${this.activeIndex} students`;
      this._getData();
      switch (key) {
        case "Enrolled":
          this.dropdownData = ["Defer", "Withdraw", "Delete"];
          break;
        case "Deferred":
          this.dropdownData = ["Return", "Withdraw"];
          break;
        case "Withdrawn":
          this.dropdownData = ["Delete", "Enroll"];
          break;
      }
    },
    changeTopSelect(value) {
      if (value === "all") {
        this.query.locationId = "";
        this._getData();
      } else {
        this.query.locationId = value;
        this._getData();
      }
      sessionStorage.setItem("lastChoiceLocation", this.query.locationId);
      // console.log(sessionStorage.getItem('lastChoiceLocation'))
    },
    _getSettingList(dataLevel, dataType, datalistName) {
      let data = {
        userId: this.user.userId,
        dataLevel,
        dataType,
      };
      this.loading = true;
      Ajax.post("/usermanage/setting/getList", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
          this[datalistName] = response || [];

          if (dataLevel === "student") {
            this.formDataList.find(
              (item) => item.key === "studentId"
            ).className = this.isAutoVisi.length > 0 ? "studentId" : "";
          }

          if (dataType === "gender") {
            let radioData = [];
            let a;
            res.data.forEach((item) => {
              a = {
                key: item.value,
                value: item.value,
              };
              radioData.push(a);
            });
            this.filterRenderData.find(
              (item) => item.filterKey === "gender"
            ).radioData = radioData;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _getData() {
      this.query.userId = this.user.userId;
      this.query.status = this.activeIndex;
      this.loading = true;
      Ajax.post("/usermanage/student/pageList", this.query)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.records;
          this.totalNum = res.data.total || 0;
          this.tableData.forEach((item, index) => {
            let a = this.formDate(item.dateOfBirth);
            if (a == "-") {
              a = null;
            }
            this.computedAge(a, index);
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    _getFamilyData(id) {
      this.contacts.loading = true;
      Ajax.get("/usermanage/parent/getListByStudentId", {
        studentId: id,
      })
        .then((res) => {
          this.contacts.loading = false;
          this.contacts.family.dataList = res.data;
        })
        .catch(() => {
          this.contacts.loading = false;
        });
    },
    _getPickupData(id) {
      this.contacts.loading = true;
      Ajax.get("/usermanage/pickup/selectByStudentId", {
        studentId: id,
      })
        .then((res) => {
          this.contacts.loading = false;
          this.contacts.pickup.dataList = res.data;
        })
        .catch(() => {
          this.contacts.loading = false;
        });
    },
    _pageChange(val) {
      this.query.current = val;
      this._getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this._getData();
    },
    _filterCallback(data) {
      let locationId = this.query.locationId || "";
      let obj = {};
      if (data.length == 0) {
        this.query = {
          size: 20,
          current: 1,
          locationId: locationId,
        };
      }

      data.filter((i) => {
        if (i.title == "Date of birth") {
          i.showVal = moment(i.filterVal).format("DD/MM/YYYY");
          i.filterVal = moment(i.filterVal).format("YYYY-MM-DD");

          obj[i.filterKey] = i.filterVal;
        } else {
          obj[i.filterKey] = i.filterVal;
        }
      });
      console.log(data);
      this.query = {
        size: 20,
        current: 1,
        locationId: locationId,
      };
      console.log(obj);

      this.query = Object.assign(obj, this.query);
      this._getData();
    },
    _onSortChange(sort) {
      this.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      this._getData();
    },
    linkTo(row) {
      sessionStorage.setItem(
        "studentInfo",
        JSON.stringify({
          studentId: row.id,
          type: "1",
          className: row.className,
          locationName: row.locationName,
          status: row.status,
        })
      );

      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "students",
          name: "Students",
        },
        {
          name: `${row.firstName} ${row.lastName}`,
        },
      ]);
      this.$store.commit("SET_LOCATIONID", row.schoolId);
      this.$router.push({
        name: "studentsInfo",
      });
    },
    handleSuspend(content) {
      this.snackbar.isShow = true;
      this.snackbar.content = content;
    },
    openMessageBox(messageObj, callback) {
      this.$confirm(messageObj.desc, messageObj.title, {
        cancelButtonText: messageObj.cancelTxt,
        confirmButtonText: messageObj.confirmTxt,
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        showClose: false,
        callback: (action) => {
          if (action == "confirm") {
            callback(action);
          }
        },
      }).then(() => {
        this.handleSuspend(messageObj.snackbarContent);
      });
    },
    enrollStudent() {
      Ajax.get("/usermanage/student/isOpenAuto", {
        userId: this.user.userId,
      })
        .then((res) => {
          if (res.code == "0000") {
            this.isShowAuto = res.data;
            this.formDialogVisible = true;

            this.formDialogTitle = "Enroll student";
            this.formDialogBtnTxt = "Enroll";
            this.formDataList.forEach((item) => {
              item.value = "";
            });
          } else {
            this.handleSuspend(res.message);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSubmit() {
      if (this.formDialogBtnTxt == "Save") {
        this._updateStudent();
      } else {
        this.handleAddForm();
      }
    },
    handleAddForm() {
      let formData = {
        firstName: "",
        lastName: "",
        studentId: "",
        schoolId: "", // locationId
        userId: this.user.userId,
      };
      this.formDataList.forEach((item) => {
        formData[item.key] = item.value;
        // if (item.key === "auto") {
        //   if (item.value) {
        //     formData.auto = "1";
        //   } else {
        //     formData.auto = "0";
        //   }
        // }
      });
      formData = Object.assign({}, formData);
      this.loading = true;
      Ajax.post("/usermanage/student/saveStudent", formData)
        .then((res) => {
          this.loading = false;
          // alert(res.code);
          if (res.code == "0000") {
            this.isStudentIdEdit = false;
            this.formDialogVisible = false;

            this.handleSuspend("Student has been enrolled");
            this._getData();
          } else {
            if (res.message == "StudentID already exists") {
              this.formDialogVisible = true;
              this.errorObj = {
                show: true,
                message: "StudentID already exists",
              };
            } else {
              alert(res.code);
              this.handleSuspend(res.message);
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    openContactsDialog(row) {
      this.contacts.visible = true;
      this._getFamilyData(row.id);
      this._getPickupData(row.id);
    },
    editStudent(row) {
      this.formDialogVisible = true;
      this.formDialogTitle = "Edit student";
      this.formDialogBtnTxt = "Save";
      this.studentId = row.studentId;
      this.selfStudentId = row.id;
      let formData = {
        firstName: row.firstName,
        lastName: row.lastName,
        studentId: row.studentId,
        schoolId: row.schoolId, // locationId
        userId: this.user.userId,
        id: row.id,
        auto: row.auto,
        inClass: row.inClass,
      };
      if (formData.auto == 1) {
        this.isStudentIdEdit = true;
      }
      this.formDataList.forEach((item) => {
        item.value = formData[item.key];
        if (item.key == "schoolId") {
          item.inClass = formData.inClass;
        }
      });
      this.is_first = false;
      this.dialogData = Object.assign({}, this.query, formData);
    },
    _updateStudent() {
      this.loading = true;
      let formData = {};
      this.formDataList.forEach((item) => {
        formData[item.key] = item.value;
      });
      let schoolName =
        (this.locationList.filter((item) => {
          return item.id == formData.schoolId;
        }) &&
          this.locationList.filter((item) => {
            return item.id == formData.schoolId;
          })[0].value) ||
        "";
      if (schoolName) {
        formData.schoolName = schoolName;
      }
      formData.id = this.selfStudentId;
      Ajax.post("/usermanage/student/updateStudentV2", formData)
        .then((res) => {
          if (res.code !== "0000") {
            // this.handleSuspend(res.message);
          }
          this.formDialogVisible = false;
          this.loading = false;
          this.handleSuspend("Student has been edited");
          this._getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleStatus(row, action) {
      if (action == "Return") {
        action = "Returned";
      } else if (action == "Enroll") {
        action = "Enrolled";
      } else if (action == "Defer") {
        action = "Deferred";
      } else if (action == "Withdraw") {
        action = "Withdrawn";
      }

      let callback = () => {
        this._updateStatus(row, action);
      };
      this.openMessageBox(this.messageObj[action], callback);
    },
    _updateStatus(row, action) {
      let formData = {
        id: row.id,
        status: action,
        className: row.className,
        locationName: row.locationName,
      };
      if (action == "Returned") {
        formData = {
          id: row.id,
          status: "Enrolled",
          className: row.className,
          locationName: row.locationName,
        };
      }
      this.loading = true;
      Ajax.post("/usermanage/student/updateStatus", formData)
        .then((res) => {
          if (res.code !== "0000") {
          }
          this.loading = false;
          this.formDialogVisible = false;
          this.handleSuspend(this.messageObj[action].snackbarContent);
          this._getData();
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  created() {
    // console.log(sessionStorage.getItem('lastChoiceLocation'))
    // if(sessionStorage.getItem('lastChoiceLocation')){
    //  let value = sessionStorage.getItem('lastChoiceLocation')
    //  if (value === "") {
    //    this.query.locationId = "";
    //    this._getData();
    //  } else {
    //    this.query.locationId = value;
    //    this._getData();
    //  }
    // }
    // this._getData();
    this._getSettingList("location", "location", "locationList");
    this._getSettingList("student", "studentId", "isAutoVisi");
    this._getSettingList("student", "gender");
    setTimeout(() => {
      if (sessionStorage.getItem("delete") == "students") {
        this.handleSuspend(`Student has been deleted`);
        sessionStorage.setItem("delete", "");
      }
    }, 1000);
  },
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.createBtnBox {
  padding-left: 8px;
  padding-bottom: 24px;
}

.checkBoxCon {
  position: relative;
  width: 100px;
  flex-basis: 100px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // /deep/ .el-tooltip__popper.text {
  //   position: absolute;
  //   left: -80%;
  //   bottom: -43px;
  //   width: 325px;
  //   height: 56px;
  //   line-height: 56px;
  //   text-align: center;
  //   border: 0;
  //   border-radius: 8px;
  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  //   display: none;
  // }
  .prompt {
    cursor: pointer;
    // &:hover {
    //   .text {
    //     display: block !important;
    //   }
    // }
  }
}

.form-dialog .content {
  min-height: 500px !important;
}

/deep/.md-checkbox {
  margin: 0;
}

/deep/.studentId {
  width: calc(50% - 100px) !important;
  flex-basis: calc(50% - 100px) !important;
}

.contactsDialog {
  .title {
    margin-bottom: 20px;
    font-family: "Roboto-medium";
    font-size: 14px;
    color: #202124;
  }

  .list-tableBox {
    margin-bottom: 20px;
    max-height: 200px;
  }
}
/deep/.base-button {
  font-family: Roboto;
}
/deep/.drop_down_menus div:last-child {
  border-top: 1px solid #e9e9e9;
}
/deep/.gg_input label {
  font-family: Roboto;
}
/* /deep/.base-button:hover.primary{
		color: #1a73e8;
		background: #d5dced!important;
	} */
.spec::after {
  height: 0;
}
/deep/.el-dialog {
  top: 50%;
  transform: translate(0%, -50%);
  margin-top: 0 !important;
}
</style>
