var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":[
      {
        routeName: 'locations',
        name: _vm.user.schoolName,
      },
      {
        name: 'Commbook',
      } ]},slot:"breadcrumb"}),_c('GgPage',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"commbook",attrs:{"pageType":"1","title":"Commbook"}},[(JSON.stringify(_vm.studentListObj) !== '{}')?[(
          _vm.$buttonPermission('Messages', 'Full') ||
          _vm.$buttonPermission('Messages', 'Edit') ||
          _vm.$buttonPermission('Messages', 'Create')
        )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShowRecipientsView),expression:"!isShowRecipientsView"}],staticClass:"commbook-header mar-b-20"},[_c('head-img',{staticClass:"user-box",attrs:{"src":_vm.user.userInfo.photo,"firstName":_vm.user.userInfo.firstName,"lastName":_vm.user.userInfo.lastName,"line-height":"36"},on:{"click":function () {}}}),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.isShowRecipientsView = true}}},[_vm._v(" Start a conversation with your students ... ")])],1):_vm._e(),(_vm.isShowRecipientsView)?_c('recipientsView',{attrs:{"isSelect":"","studentListObj":_vm.studentListObjTemp},on:{"onCancel":function($event){_vm.isShowRecipientsView = false},"onSave":_vm.onRecipientsSave}}):_vm._e(),_vm._l((_vm.studentArr),function(item,key){return _c('section-list',{key:key,staticClass:"mar-b-20",attrs:{"isShowLastName":"","title":item[0].className,"data":item,"iconButton":false},on:{"handleClick":_vm.toStudentPage}},[_c('template',{slot:"icon"},[_c('head-img',{staticClass:"user-box",staticStyle:{"margin-right":"10px"},style:({ backgroundColor: item[0] && item[0].themeColor }),attrs:{"line-height":"28","firstName":item[0].className,"lastName":("" + (item[0].className.split(' ').length > 1
                ? item[0].className.split(' ')[1]
                : ''))},on:{"click":function () {}}})],1)],2)})]:_vm._e(),(JSON.stringify(_vm.studentListObj) === '{}')?_c('edoovo-table',{attrs:{"height":"100%","rowKey":"id","defaultValue":"-"}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No message yet.")])])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }