var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gg-Dialog',{attrs:{"visible":_vm.isShowDialog,"before-close":function () {
        _vm.$emit('cancel');
      }},on:{"update:visible":function($event){_vm.isShowDialog=$event}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"recipients"},[_c('div',[_c('div',{staticStyle:{"overflow":"hidden","margin-bottom":"10px"}},[_c('md-field',{staticStyle:{"float":"left","width":"48%","background-color":"#f8f8f8"}},[_c('label',{attrs:{"for":"movie"}},[_vm._v("Topic")]),_c('md-select',{attrs:{"name":"movie","id":"movie"},model:{value:(_vm.paramsDetail_.topic),callback:function ($$v) {_vm.$set(_vm.paramsDetail_, "topic", $$v)},expression:"paramsDetail_.topic"}},_vm._l((_vm.topicList),function(item,key){return _c('md-option',{key:key,attrs:{"value":item.value}},[_vm._v(_vm._s(item.value))])}),1)],1),_c('gg-input',{staticStyle:{"float":"right","width":"48%","background-color":"#f8f8f8"},attrs:{"placeholder":"Title","error":_vm.errorTitle},model:{value:(_vm.paramsDetail_.title),callback:function ($$v) {_vm.$set(_vm.paramsDetail_, "title", $$v)},expression:"paramsDetail_.title"}})],1),_c('div',{staticClass:"edit-editing"},[_c('editor',{attrs:{"init":_vm.editorInit},model:{value:(_vm.paramsDetail_.message),callback:function ($$v) {_vm.$set(_vm.paramsDetail_, "message", $$v)},expression:"paramsDetail_.message"}})],1),(_vm.errorMessage.show)?_c('div',{staticClass:"edit-error"},[_vm._v(" "+_vm._s(_vm.errorMessage.message)+" ")]):_vm._e(),(_vm.paramsDetail_.fileList)?_c('imageListPreview',{attrs:{"fileList":_vm.paramsDetail_.fileList,"isEdit":true},on:{"loading":_vm.changeloading}}):_vm._e(),_c('div',{staticStyle:{"overflow":"hidden","padding-top":"20px"}},[_c('el-upload',{staticStyle:{"display":"inline-block"},attrs:{"auto-upload":false,"action":"","accept":"image/jpeg,image/png,application/pdf","show-file-list":false,"on-change":function (file, fileList) {
                _vm.onUploadFileChange(file, fileList);
              },"before-upload":_vm.beforeUpload,"disabled":_vm.paramsDetail_.fileList && _vm.paramsDetail_.fileList.length != 0}},[_c('baseButton',{staticClass:"contained",staticStyle:{"margin-top":"8px"},attrs:{"disabled":_vm.paramsDetail_.fileList && _vm.paramsDetail_.fileList.length != 0,"size":"small","type":"primary"}},[_c('span',{staticClass:"icon iconfont iconattach_file-web"}),_vm._v("Add")])],1),_c('div',{staticStyle:{"float":"right"}},[_c('base-button',{on:{"click":_vm.onCancel}},[_vm._v("Cancel")]),_c('base-button',{attrs:{"title":!_vm.paramsDetail_.message ||
                JSON.stringify(_vm.chooseStudentObj) == '{}'
                  ? 'Cannot post without students,topic and message'
                  : '',"type":"primary","disabled":!(
                  _vm.paramsDetail_.message &&
                  _vm.paramsDetail_.title &&
                  _vm.paramsDetail_.topic
                ) ||
                (_vm.isSelect && JSON.stringify(_vm.chooseStudentObj) == '{}') ||
                _vm.errorTitle.show ||
                _vm.errorMessage.show},on:{"click":_vm.onPost}},[_vm._v("Save")])],1)],1)],1),(_vm.isImgCutShow)?_c('img-cut-modal',{attrs:{"visible":_vm.isImgCutShow,"acceptPdfFile":true,"isCut":true},on:{"update:visible":function($event){_vm.isImgCutShow=$event},"_uploadCallback":_vm._uploadCallback,"_uploadPdfCallback":_vm._uploadPdfCallback}}):_vm._e(),_c('selectStudentsDialog',{attrs:{"dialogVisible":_vm.selectStudentsDialogVisible,"studentListObj":_vm.studentListObj,"selectedStudentObj":_vm.chooseStudentObj},on:{"cancel":function($event){_vm.selectStudentsDialogVisible = false},"save":_vm.onSelectStudents}}),_c('Snackbar',{attrs:{"visible":_vm.snackbar.isShow,"content":_vm.snackbar.content},on:{"update:visible":function($event){return _vm.$set(_vm.snackbar, "isShow", $event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }