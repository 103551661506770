<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'announcementPosted',
          name: activeIndex,
        },
        {
          name: htmlDecode(announcementdData.title),
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage topType="location" pageType="2" title="Announcements">
      <div slot="topAppBar"><search></search></div>
      <template v-slot:left>
        <div
          class="mar-l-b"
          v-if="
            hasPermission('Announcements_Create') && activeIndex != 'Create'
          "
        >
          <gg-create-button
            @click="openDialogAdd(null)"
            label="Create announcement"
          />
        </div>
        <gg-flex-menus
          :menus="menuDatas"
          @select="onMenuSelect"
          :default-active="activeIndex"
        />
      </template>

      <div class="announcement-inner collapse-list">
        <div class="title">
          <div class="tit">{{ htmlDecode(announcementdData.title) }}</div>
          <div class="des">
            <div class="avatar" v-if="announcementdData.publishUserPhoto">
              <img :src="announcementdData.publishUserPhoto" />
            </div>
            <div class="no" v-else>
              {{
                announcementdData.publishUserFirstName
                  ? announcementdData.publishUserFirstName
                      .substring(0, 1)
                      .toUpperCase()
                  : "-"
              }}{{
                announcementdData.publishUserLastName
                  ? announcementdData.publishUserLastName
                      .substring(0, 1)
                      .toUpperCase()
                  : "-"
              }}
            </div>
            <div class="name">
              {{
                announcementdData.publishUserFirstName ||
                "" + " " + announcementdData.publishUserLastName ||
                ""
              }}
            </div>
            <div class="tag">{{ announcementdData.roleName }}</div>
            <div class="date">
              posted on {{ momentTime(announcementdData.releaseTime) }}
            </div>
          </div>
          <div class="draft-col" v-if="hasPermission('Announcements_Full')">
            <gg-menu-bar>
              <i class="iconmore_vert-24px iconfont" />
              <template slot="menus">
                <gg-menu-bar-item
                  v-for="(item, key) in menuData"
                  @click="menuClick(item)"
                  :disabled="item.isDisabled"
                  :key="key"
                  ><span>{{
                    typeof item === "object" ? item.label : item
                  }}</span></gg-menu-bar-item
                >
              </template>
            </gg-menu-bar>
          </div>
        </div>
        <div class="announcement-inner">
          <div class="draft-con">
            <div class="edit-box">
              <div class="edit-name">Details</div>

              <div class="edit-editing" v-html="announcementdData.body"></div>

              <div class="file-inner">
                <div class="file-list-inner">
                  <div
                    class="file-box"
                    @click="viewfile(item)"
                    v-for="(item, index) in announcementdData.addFile"
                    :key="index"
                  >
                    <div class="file-head">
                      <pdf
                        style="width: 103px; height: 72px; text-align: center"
                        class="pdf-preview"
                        v-if="returnFileFormat(item.url) === 'pdf'"
                        :id="item.id"
                        :page="1"
                        :src="item.url"
                      />
                      <el-image
                        v-else
                        style="width: 103px; height: 72px"
                        :src="item.url"
                        fit="cover"
                      >
                      </el-image>
                    </div>
                    <div class="file-body">
                      <div class="file-name">File name: {{ item.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="response-inner">
              <div class="response-col">
                <div class="response-name">Recipients</div>

                <div class="section-inner">
                  <div
                    class="section-box"
                    :class="{
                      have: item.sumStudents > 0 && item.className != 'All',
                    }"
                    @click="getParentView(item)"
                    v-for="(item, index) in recipients"
                    :key="index"
                  >
                    <div class="section-head clearfix">
                      <div class="section-head-l">
                        <div class="section-des">
                          <div
                            class="no"
                            :style="'background:' + item.themeColor"
                          >
                            {{
                              item.className
                                ? getClassNameAI(item.className)
                                : "-"
                            }}
                          </div>
                          <div class="name">{{ item.className }}</div>
                        </div>
                      </div>

                      <div class="section-head-m">
                        <ul class="section-item">
                          <li>
                            <div class="val">
                              {{
                                item.sumStudents === "0"
                                  ? "-"
                                  : item.sumStudents
                              }}
                            </div>
                            <div class="tit">Total</div>
                          </li>
                          <li>
                            <div class="val">
                              {{
                                item.recipients === "0" ? "-" : item.recipients
                              }}
                            </div>
                            <div class="tit">Recipients</div>
                          </li>
                        </ul>
                      </div>

                      <div class="section-head-r">
                        <ul class="section-item">
                          <li>
                            <div class="val">
                              {{
                                item.rateViewed === "0%" ? "-" : item.rateViewed
                              }}
                            </div>
                            <div class="tit">Viewed</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="response-inner"
              v-if="announcementdData.questionContent.options.length > 0"
            >
              <div class="response-col">
                <div class="response-name">
                  Responses
                  <span
                    v-if="
                      announcementdData.questionContent.dueDate &&
                      announcementdData.questionContent.dueDate
                    "
                    class="date"
                    >Due on
                    {{
                      responsesMomentTime(
                        announcementdData.questionContent.dueDate
                      )
                    }}</span
                  >
                </div>

                <div class="section-inner">
                  <div
                    class="section-box"
                    :class="{
                      have: item.students > 0 && item.className != 'All',
                    }"
                    @click="getParentResponded(item)"
                    v-for="(item, index) in response"
                    :key="index"
                  >
                    <div class="section-head clearfix">
                      <div class="section-head-l">
                        <div class="section-des">
                          <div
                            class="no"
                            :style="'background:' + item.themeColor"
                          >
                            {{
                              item.className
                                ? getClassNameAI(item.className)
                                : "-"
                            }}
                          </div>
                          <div class="name">{{ item.className }}</div>
                        </div>
                      </div>

                      <div class="section-head-m">
                        <ul class="section-item">
                          <li
                            v-for="(reitem, reindex) in item.respondedDtoList"
                            :key="reindex"
                            v-show="reindex < item.respondedDtoList.length - 1"
                          >
                            <div class="val">
                              {{
                                reitem.percentage === "0%"
                                  ? "-"
                                  : reitem.percentage
                              }}
                            </div>
                            <div class="tit">{{ reitem.options }}</div>
                          </li>
                        </ul>
                      </div>

                      <div class="section-head-r">
                        <ul class="section-item">
                          <li>
                            <div class="val">
                              {{
                                item.respondedDtoList[
                                  item.respondedDtoList.length - 1
                                ].percentage === "0%"
                                  ? "-"
                                  : item.respondedDtoList[
                                      item.respondedDtoList.length - 1
                                    ].percentage
                              }}
                            </div>
                            <div class="tit">
                              {{
                                item.respondedDtoList[
                                  item.respondedDtoList.length - 1
                                ].options
                              }}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </GgPage>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />

    <el-dialog
      ref="postedDialog"
      class="posted-dialog"
      :title="parentViewList.info.className"
      width="1160px"
      v-if="dialogViewedVisible"
      :visible.sync="dialogViewedVisible"
    >
      <div class="class-icon" :style="'background:' + this.classIcon.color">
        {{ this.classIcon.name.toUpperCase() }}
      </div>
      <div>
        <md-table
          v-model="parentViewList.list"
          md-sort="studentName"
          md-sort-order="asc"
          md-card
          md-fixed-header
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell
              md-label="Student"
              md-sort-by="top.studentName"
              :class="{ num: item.list.length > 1 }"
            >
              <div class="table-box-line">
                <img
                  :src="item.top.studentPhoto"
                  class="avatar"
                  v-if="item.top.studentPhoto"
                />
                <div class="no" v-else>
                  {{
                    item.top.studentFirstName
                      ? item.top.studentFirstName.substring(0, 1).toUpperCase()
                      : "-"
                  }}{{
                    item.top.studentLastName
                      ? item.top.studentLastName.substring(0, 1).toUpperCase()
                      : "-"
                  }}
                </div>
                {{ item.top.studentName.replace(/(^\s*)|(\s*$)/g, "") || "-" }}
              </div>
            </md-table-cell>
            <md-table-cell md-label="Family">
              <div
                class="table-box-line"
                v-for="(lineItem, lineIndex) in item.list"
                :key="lineIndex"
              >
                <img
                  :src="lineItem.parentPhoto"
                  class="avatar"
                  v-if="lineItem.parentPhoto"
                />
                <div class="no" v-else>
                  {{
                    lineItem.parentFirstName
                      ? lineItem.parentFirstName.substring(0, 1).toUpperCase()
                      : "-"
                  }}{{
                    lineItem.parentLastName
                      ? lineItem.parentLastName.substring(0, 1).toUpperCase()
                      : "-"
                  }}
                </div>
                {{
                  (
                    (lineItem.parentFirstName || "") +
                    " " +
                    (lineItem.parentLastName || "")
                  ).replace(/(^\s*)|(\s*$)/g, "") || "-"
                }}
              </div>
            </md-table-cell>
            <md-table-cell md-label="Email">
              <div
                class="table-box-line"
                v-for="(lineItem, lineIndex) in item.list"
                :key="lineIndex"
              >
                {{ lineItem.parentEmail || "-" }}
              </div>
            </md-table-cell>
            <md-table-cell md-label="Viewed" class="avp">
              <div
                class="table-box-line"
                v-for="(lineItem, lineIndex) in item.list"
                :key="lineIndex"
              >
                <img
                  style="margin-left: 12px"
                  v-if="lineItem.viewed"
                  src="@/assets/images/icons/yes.svg"
                  class="avatar np"
                />
                <img
                  style="margin-left: 12px"
                  v-if="!lineItem.viewed"
                  src="@/assets/images/icons/no.svg"
                  class="avatar np"
                />
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </el-dialog>

    <el-dialog
      ref="postedDialog"
      class="posted-dialog"
      :title="parentViewList.info.className"
      width="1160px"
      v-if="dialogResponseVisible"
      :visible.sync="dialogResponseVisible"
    >
      <div class="class-icon" :style="'background:' + this.classIcon.color">
        {{ this.classIcon.name.toUpperCase() }}
      </div>
      <div>
        <md-table
          v-model="parentViewList.list"
          md-sort="studentName"
          md-sort-order="asc"
          md-card
          md-fixed-header
        >
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell
              width="1160px"
              md-label="Student"
              md-sort-by="studentName"
            >
              <img
                :src="item.studentPhoto"
                class="avatar"
                v-if="item.studentPhoto"
              />
              <div class="no" v-else>
                {{
                  item.studentFirstName
                    ? item.studentFirstName.substring(0, 1).toUpperCase()
                    : "-"
                }}{{
                  item.studentLastName
                    ? item.studentLastName.substring(0, 1).toUpperCase()
                    : "-"
                }}
              </div>
              {{ item.studentName.replace(/(^\s*)|(\s*$)/g, "") || "-" }}
            </md-table-cell>
            <md-table-cell md-label="Primary contact">
              <img
                :src="item.parentPhoto"
                class="avatar"
                v-if="item.parentPhoto"
              />
              <div class="no" v-else>
                {{
                  item.parentFirstName
                    ? item.parentFirstName.substring(0, 1).toUpperCase()
                    : "-"
                }}{{
                  item.parentLastName
                    ? item.parentLastName.substring(0, 1).toUpperCase()
                    : "-"
                }}
              </div>
              {{ item.parentName.replace(/(^\s*)|(\s*$)/g, "") || "-" }}
            </md-table-cell>
            <md-table-cell md-label="Email">{{
              item.parentEmail || "-"
            }}</md-table-cell>
            <md-table-cell md-label="Response" md-sort-by="respondedContent">
              {{ item.respondedContent || "-" }}
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </el-dialog>

    <Snackbar :visible.sync="loadingShow" :content="loadingText" />

    <gg-Dialog
      width="580px"
      :visible.sync="isDialogShow1"
      title="Recall announcement?"
      class="draft-dialog mp-dialog"
    >
      <div class="dialogContent">
        Once recalled, parents will not be able to see the announcement and the
        announcement will move to draft.
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDialogShow1 = false"
          >Cancel</gg-cancel-button
        >
        <gg-submit-button @click="updateAnnouncementStatus('draft')"
          >Recall</gg-submit-button
        >
      </span>
    </gg-Dialog>

    <gg-Dialog
      width="580px"
      :visible.sync="isDialogShow2"
      title="Archive announcement?"
      class="draft-dialog mp-dialog"
    >
      <div class="dialogContent">
        Once archived, the announcement will move to archived.
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDialogShow2 = false"
          >Cancel</gg-cancel-button
        >
        <gg-submit-button @click="updateAnnouncementStatus('archive')"
          >Archive</gg-submit-button
        >
      </span>
    </gg-Dialog>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import search from "../modules/search";
import moment from "moment";
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { hasPermission } from "./../utils/utils.js";
export default {
  components: {
    pdf,
    search,
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      classIcon: {},
      users: [
        {
          id: 1,
          name: "Shawna Dubbin",
          email: "sdubbin0@geocities.com",
          gender: "Male",
          title: "Assistant Media Planner",
        },
        {
          id: 2,
          name: "Odette Demageard",
          email: "odemageard1@spotify.com",
          gender: "Female",
          title: "Account Coordinator",
        },
        {
          id: 3,
          name: "Lonnie Izkovitz",
          email: "lizkovitz3@youtu.be",
          gender: "Female",
          title: "Operator",
        },
        {
          id: 4,
          name: "Thatcher Stave",
          email: "tstave4@reference.com",
          gender: "Male",
          title: "Software Test Engineer III",
        },
        {
          id: 5,
          name: "Clarinda Marieton",
          email: "cmarietonh@theatlantic.com",
          gender: "Female",
          title: "Paralegal",
        },
      ],
      rootPath: process.env.VUE_APP_fileBasePath,
      parentViewList: {
        list: [],
        info: {},
      },
      updateStatus: "",
      isDialogShow1: false,
      isDialogShow2: false,
      status: {
        DRAFT: 0,
        POSTED: 1,
        ARCHIVE: 2,
      },
      loadingText: "",
      loadingShow: false,
      response: [],
      announcementdData: {},
      id: "",
      recipients: [],
      users: [
        {
          id: 1,
          name: "Shawna Dubbin",
          avatar: "http://placekitten.com/g/208/144",
          email: "sdubbin0@geocities.com",
          gender: "Male",
          title: "Assistant Media Planner",
        },
        {
          id: 2,
          name: "Odette Demageard",
          email: "odemageard1@spotify.com",
          avatar: "http://placekitten.com/g/208/144",
          gender: "Female",
          title: "Account Coordinator",
        },
      ],
      dialogViewedVisible: false,
      dialogResponseVisible: false,
      responseRadio: "",
      menuData: ["Recall", "Archive"],
      activeIndex: "Posted",
      fileProgress: true,
      amount: 50,
      selectedDate: null,
      loading: false,
      module: {
        title: "",
        moduleType: "",
        moduleName: "",
      },
      disabledAddoption: true,
      tableData: [],
      // isAllowed: true,
      oldVal: "",
      snackbar: { isShow: false, content: "" },
      responseBoxShow: true,
      content: "",
      classList: [],
      menuDatas: [
        {
          name: "Draft",
          key: "Draft",
          active: true,
        },
        {
          name: "Posted",
          key: "Posted",
          active: true,
        },
        {
          name: "Archived",
          key: "Archived",
          active: true,
        },
      ],
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  created() {
    this.getClass();
    if (this.$route.params.id) {
      this.id = this.$route.params.id || "";
      this.announcementdData = JSON.parse(
        sessionStorage.getItem("announcementdData")
      );
      this.announcementdData.body = this.htmlDecode(
        this.announcementdData.body
      );
      this.getRecipients();
      this.getResponse();
    }
  },
  mounted() {},
  methods: {
    hasPermission,

    htmlDecode(text) {
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },

    getClassNameAI(name) {
      let aiName = "";
      if (name === "All") {
        aiName = "All";
      } else {
        if (name.indexOf(" ") > -1) {
          let cname = name.split(" ");
          aiName =
            cname[0].substring(0, 1).toUpperCase() +
            cname[1].substring(0, 1).toUpperCase();
        } else {
          aiName = name.substring(0, 1).toUpperCase();
        }
      }

      return aiName;
    },

    getParentResponded(item) {
      if (item.students > 0 && item.className != "All") {
        let data = {};
        data.announcementsId = this.id;
        data.classId = item.classId;
        this.classIcon = {
          color: item.themeColor,
          name: this.getClassNameAI(item.className),
        };

        Ajax.get("/announcement/announcements/getParentRespondedV1", data)
          .then((res) => {
            if (res.code === "0000") {
              let arr = res.data;
              for (let i = 0; i < arr.length; i++) {
                arr[i].parentName =
                  (arr[i].parentFirstName || "") +
                  " " +
                  (arr[i].parentLastName || "");
                arr[i].studentName =
                  (arr[i].studentFirstName || "") +
                  " " +
                  (arr[i].studentLastName || "");
              }
              this.parentViewList.list = arr;
              this.parentViewList.info = item;
              this.dialogResponseVisible = true;

              setTimeout(() => {
                this.dialogMiddle();
              }, 50);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    getParentView(item) {
      if (item.sumStudents > 0 && item.className != "All") {
        let data = {};
        data.announcementsId = this.id;
        data.classId = item.classId;
        this.classIcon = {
          color: item.themeColor,
          name: this.getClassNameAI(item.className),
        };

        Ajax.get("/announcement/announcements/getParentViewedV1", data)
          .then((res) => {
            if (res.code === "0000") {
              let arr = res.data,
                tempArr = [];

              for (let i = 0; i < arr.length; i++) {
                let p = {};
                for (let key in arr[i]) {
                  p.top = JSON.parse(key);
                  p.top.studentName =
                    (p.top.studentFirstName || "") +
                    " " +
                    (p.top.studentLastName || "");
                  p.list = arr[i][key];
                }
                tempArr.push(p);
              }

              this.parentViewList.list = tempArr;
              this.parentViewList.info = item;
              this.dialogViewedVisible = true;

              setTimeout(() => {
                this.dialogMiddle();
              }, 50);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    dialogMiddle() {
      let dom = document
        .querySelector(".posted-dialog")
        .querySelector(".el-dialog");
      let h = dom.clientHeight;
      dom.style.marginTop = "-" + h / 2 + "px";
    },

    menuClick(e) {
      let id = this.id;
      if (e === "Recall") {
        this.isDialogShow1 = true;
      }
      if (e === "Archive") {
        this.isDialogShow2 = true;
      }
    },

    updateAnnouncementStatus(status) {
      let data = {};
      data.id = this.id;
      data.publishUser = this.user.userId;
      data.status = status;
      this.isDialogShow1 = false;
      this.isDialogShow2 = false;

      Ajax.post("/announcement/announcements/updateStatusByIdV1", data)
        .then((res) => {
          if (res.code === "0000") {
            this.loadingText = "Announcement has been " + status + "ed";
            this.loadingShow = true;

            setTimeout(() => {
              if (status === "archive") {
                this.$router.push({ name: "announcementArchived" });
              } else if (status === "draft") {
                this.$router.push({ name: "announcement" });
              }
            }, 1200);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    returnFileFormat(url) {
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      return url.match(reg)[1];
    },

    viewfile(file) {
      const needPrint = 1;
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileFormat = file.name.match(reg)[1];

      if (fileFormat === "pdf") {
        let routeData = this.$router.resolve({
          path: "/testFileView",
          query: { url: file.url, name: file.name, needPrint: needPrint },
        });
        window.open(routeData.href, "_blank");
      } else {
        let routeData = this.$router.resolve({
          path: "/testFileView",
          query: { imgUrl: file.url, name: file.name, needPrint: needPrint },
        });
        window.open(routeData.href, "_blank");
      }
    },

    getRecipients() {
      let data, id;
      id = this.id;
      data = {
        userId: this.user.userId,
        announcementId: this.id,
      };
      Ajax.get(
        "/announcement/announcements/getRecipientsByIdV1/" + id,
        data
      ).then((res) => {
        if (res.code === "0000") {
          this.recipients = res.data;
        }
      });
    },

    getResponse() {
      let data, id;
      id = this.id;
      data = {
        announcementId: this.id,
        userId: this.user.userId,
      };
      Ajax.get("/announcement/announcements/getResponseV1/" + id, data).then(
        (res) => {
          if (res.code === "0000") {
            this.response = res.data;
          }
        }
      );
    },

    responsesMomentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY");
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    },

    getClassInfo() {
      let id = this.id,
        data = {};
      data.id = id;
      Ajax.get("/announcement/announcements/getRecipientsByIdV1/" + id, data)
        .then((res) => {
          if (res.code === "0000") {
            this.classselectList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getClass() {
      let data = {
        locationId: this.user.locationId,
      };
      Ajax.get("/usermanage/class/selectByCompanyIdLocationId", data).then(
        (res) => {
          let tempdata = [],
            temparr;
          for (let item of res.data) {
            temparr = item;
            temparr.select = false;
            temparr.ai = item.classNameReplace;
            tempdata.push(temparr);

            if (this.$route.query.editid) {
              this.getClassInfo();
            }
          }
          this.classList = tempdata;
        }
      );
    },

    toStudentListPage() {},

    openDialogAdd() {
      this.$router.push({ name: "announcementCreate" });
    },

    onMenuSelect(key, item) {
      this.activeIndex = key;

      if (key === "Draft") {
        this.$router.push({ name: "announcement" });
      } else {
        this.$router.push({ name: "announcement" + key });
      }
      // this.query.status = key;
      // this.usersData.tableTitle = `${item.name} Staff`;
      // this.getData();
    },
  },
};
</script>
<style lang="less" scoped>
.collapse-list {
  .title {
    position: relative;
    padding-left: 8px;
    padding-bottom: 26px;
    border-bottom: 1px solid #e3e3e3;
    color: #202124;
    font-size: 22px;
    font-weight: 600;
    font-family: OpenSans-Bold;

    .draft-col {
      position: absolute;
      right: 20px;
      top: 0;
    }

    .tit {
      margin-bottom: 5px;
      line-height: 27px;
      font-size: 20px;
      font-family: Open Sans;
      font-weight: 600;
      line-height: 27px;
      padding-right: 70px;
      word-wrap: break-word;
    }

    .des {
      .no {
        display: inline-block;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        background: #b4b4b4;
        border-radius: 50%;
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-family: Open Sans;
        font-weight: 600;
      }

      .avatar {
        margin-right: 8px;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;

        img {
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }

      .name {
        margin-right: 8px;
        display: inline-block;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 19px;
        color: #202124;
        vertical-align: middle;
      }

      .tag {
        margin-right: 8px;
        display: inline-block;
        padding: 4px 9px;
        height: 20px;
        line-height: 12px;
        font-weight: 400;
        background: #e0e0e0;
        font-family: Roboto;
        border-radius: 10px;
        font-size: 12px;
        vertical-align: middle;
      }

      .date {
        margin-right: 8px;
        display: inline-block;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 16px;
        color: #b4b4b4;
      }
    }
  }
}

.announcement-inner {
  font-family: "Roboto";
  padding-top: 20px;

  .draft-con {
    background: #fff;
    overflow: hidden;

    .edit-box {
      border: 1px solid #e0e0e0;
      border-radius: 13px;

      .edit-name {
        padding-left: 20px;
        line-height: 68px;
        border-bottom: 1px solid #e0e0e0;
        color: #5f6368;
        font-size: 20px;
        font-family: "opensans-semibold";
      }

      .edit-tit {
        padding: 0 20px;

        .md-field.md-theme-default:after {
          border-bottom: 1px solid #e0e0e0;
        }
      }

      .edit-editing {
        padding: 20px;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 19px;
        word-break: break-all;
        color: #202124;
      }

      .file-inner {
        padding: 0 20px 20px 20px;

        .add-file-btn {
          width: 104px;
          height: 32px;
          line-height: 32px;
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          cursor: pointer;
          font-size: 13px;
          color: #5f6368;

          .add-file-icon {
            margin: 0 5px 0 10px;
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }

          &:hover {
            background-color: #e8f0fe;
          }
        }

        .file-list-inner {
          .file-box {
            position: relative;
            margin-top: 10px;
            height: 72px;
            line-height: 72px;
            background: #fff;
            border: 1px solid #e0e0e0;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            .file-head {
              float: left;
              width: 103px;
              height: 72px;

              img {
                display: block;
                width: 103px;
                height: 72px;
              }
            }

            .file-body {
              padding: 0 20px;
              font-size: 12px;
              color: #202124;
              overflow: hidden;

              .delete {
                position: absolute;
                right: 20px;
                top: 22px;
                width: 24px;
                height: 24px;
                cursor: pointer;

                img {
                  display: block;
                  width: 24px;
                  height: 24px;
                }

                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
    }

    .response-inner {
      &:last-child {
        margin-bottom: 112px;
      }

      .response-option-inner {
        padding: 20px;

        .response-tit {
          margin-bottom: 20px;
          font-size: 14px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 19px;
          color: #202124;
        }

        .response-option {
          line-height: 19px;
        }
      }

      .add-response-btn {
        margin: 20px 0;
        color: #1a73e8;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }

      .response-col {
        margin-top: 20px;
        border: 1px solid #e0e0e0;
        border-radius: 13px;

        .response-name {
          position: relative;
          padding-left: 20px;
          line-height: 68px;
          border-bottom: 1px solid #e0e0e0;
          color: #5f6368;
          font-size: 20px;
          font-family: "opensans-semibold";

          .date {
            margin-left: 10px;
            font-size: 12px;
            font-family: Roboto;
            font-weight: 400;
            line-height: 16px;
            color: #b4b4b4;
          }

          .delete {
            position: absolute;
            right: 20px;
            top: 22px;
            width: 24px;
            height: 24px;
            cursor: pointer;

            img {
              display: block;
              width: 24px;
              height: 24px;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }

        .response-info {
          .response-date {
            float: right;
            position: relative;
            margin: 0 20px 0 80px;
            width: 220px;

            .calendar-today {
              position: absolute;
              right: 0;
              top: 21px;
              width: 24px;
              height: 24px;
            }
          }

          .response-tit {
            padding: 0 0 0 20px;
            overflow: hidden;

            .md-field.md-theme-default:after {
              border-bottom: 1px solid #e0e0e0;
            }
          }
        }
      }
    }
  }

  .draft-right {
    float: right;
    padding-left: 20px;
    width: 364px;
    background: #fff;

    .cancel {
      position: fixed;
      right: 80px;
      bottom: 120px;

      .tit {
        float: left;
        margin-right: 20px;
        font-size: 13px;
        color: #5f6368;
        line-height: 33px;
      }

      .con {
        float: left;

        .el-button {
          width: 92px;
          padding: 0 32px 0 0;
          text-align: center;
          line-height: 35px;

          .el-tooltip {
            display: block;
            border-right: 1px solid #dcdfe6;
            font-weight: normal;
            color: #1a73e8;
          }

          .el-tooltip.disabled {
            color: #b4b4b4;
            font-weight: bold;
          }

          .iconfont {
            position: absolute;
            right: 10px;
            top: 0;
          }

          * {
            font-weight: bold;
            font-size: 13px !important;
          }
        }
      }
    }

    .for-box {
      width: 344px;
      border: 1px solid #e0e0e0;
      border-radius: 13px;

      .for-name {
        position: relative;
        padding-left: 20px;
        line-height: 68px;
        color: #5f6368;
        font-size: 20px;
        font-family: "Open Sans";
      }

      .for-dropdown {
        position: relative;
        margin: auto 20px;
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        font-size: 14px;
        color: #5f6368;
        cursor: pointer;

        &:hover {
          .dropdown-list {
            display: block;
          }
        }

        .arrow-drop {
          position: absolute;
          right: 4px;
          top: 4px;
          width: 20px;
          height: 20px;
        }

        .dropdown-list {
          display: none;
          position: absolute;
          left: 0;
          top: 32px;
          padding: 8px 0;
          width: 300px;
          max-height: 156px;
          background: #fff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          overflow-y: auto;

          .select-all {
            padding-left: 16px;
            line-height: 28px;
            font-size: 14px;
            color: #202124;

            .el-checkbox {
              width: 100%;
              color: #202124;
            }

            &:hover {
              background-color: #eee;
            }
          }

          .class-list {
            padding: 0;

            li {
              .el-checkbox {
                width: 100%;
              }

              &:hover {
                background-color: #eee;
              }
            }
          }
        }
      }

      .class-list {
        padding: 8px 0 0 0;

        li {
          padding: 0 0 0 16px;
          line-height: 28px;
          font-size: 14px;
          color: #202124;

          .no {
            display: inline-block;
            margin-right: 8px;
            width: 20px;
            height: 20px;
            background: #7e7e7e;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            color: #fff;
            font-size: 11px;
          }

          .name {
            display: inline-block;
            font-size: 14px;
            color: #202124;
          }
        }
      }

      .for-select-list {
        padding: 0 20px 20px 20px;
      }
    }
  }
}

// 选项
.el-table {
  /deep/tbody tr:hover {
    .isDisplay {
      display: inherit !important;
    }
  }
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff;
}
/deep/.section-main {
  margin: 0;
  max-height: calc(100vh - 112px);
  overflow-y: auto;
}
/deep/.el-table td {
  padding: 3px 0;
}
.list-table {
  .not-allowed {
    cursor: not-allowed !important;
    color: #cbcbcb !important;
  }
  .iconfont {
    font-size: 24px;
    line-height: 24px;
    margin-left: 16px;
    cursor: pointer;
  }
  .rowName {
    position: relative;
    padding-left: 40px;
    font-family: Roboto;
    font-size: 14px;
    color: #202124;

    .md-input {
      font-size: 14px;
    }

    .my-handle {
      position: absolute;
      cursor: grab;
      left: 0;
      transform: translateY(-50%);
      top: 50%;
    }

    .editName {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .cell {
    .action {
      position: absolute;
      top: 12px;
      right: 0;
    }
  }

  .el-table .cell {
    overflow: inherit;
  }
  .md-field {
    margin-bottom: 0;
    padding-top: 0;
    min-height: 32px;
  }
  .md-field.md-invalid {
    margin-bottom: 20px;
  }
  .md-field .md-error {
    bottom: -13px;
  }
}

.wraper {
  padding: 20px 0 20px 10px;

  .append {
    .base-button {
      font-family: Roboto;
      font-size: 13px;
      color: #1a73e8;
    }
  }
}

.file-progress {
  position: fixed;
  left: 50%;
  bottom: 0;
  margin-left: -170px;
  padding: 15px;
  width: 340px;
  height: 80px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 14px;

  dt {
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
  }

  .stop {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 6px 12px;

    &:hover {
      background: #1a73e8;
      color: #fff;
    }
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8px;
    border-radius: 0 0 4px 4px;
  }
}

.section-inner {
  padding-bottom: 0;

  .section-box {
    position: relative;
    background: #fff;
    box-sizing: border-box;
    color: #202124;
    border-radius: 13px;
    transition: ease 0.4s;

    &.have {
      cursor: pointer;
    }

    &:last-child .section-head {
      border-radius: 0 0 13px 13px;
    }

    &:hover {
      z-index: 1;
      border-radius: 13px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      .section-head {
        border-radius: 13px;
      }
    }

    .section-head {
      position: relative;
      padding: 20px;
      height: 92px;
      background-color: #fff;
      border-bottom: 1px solid #e0e0e0;
      box-sizing: border-box;
      overflow: hidden;

      .section-head-l {
        float: left;
        width: 300px;

        .section-des {
          padding-top: 6px;

          .no {
            display: inline-block;
            margin-right: 20px;
            width: 32px;
            height: 32px;
            background: #1a73e8;
            border-radius: 50%;
            line-height: 32px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            font-family: Open Sans;
            font-weight: 600;
          }

          .name {
            display: inline-block;
            font-size: 18px;
            font-family: Open Sans;
            font-weight: 400;
            line-height: 36px;
            color: #202124;
            vertical-align: middle;
          }
        }
      }

      .section-head-m {
        float: left;
      }

      .section-head-r {
        float: right;
        width: 80px;

        .section-item {
          float: none;

          li {
            margin-right: 0 !important;
          }
        }
      }

      .section-head-r,
      .section-head-m {
        .section-item {
          float: left;

          li {
            float: left;
            width: 100px;

            .val {
              margin-bottom: 3px;
              font-size: 22px;
              font-family: Open Sans;
              font-weight: 600;
              line-height: 30px;
              color: #202124;
            }

            .tit {
              font-size: 14px;
              font-family: Roboto;
              font-weight: 400;
              line-height: 19px;
              color: #b4b4b4;
              max-width: 150px;
            }
          }
        }
      }
    }
  }
}

.class-icon {
  position: absolute;
  left: 20px;
  top: 16px;
  display: inline-block;
  margin-right: 20px;
  width: 32px;
  height: 32px;
  background: #1a73e8;
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-family: "opensans-semibold";
}

.posted-dialog .md-table-cell {
  color: #20212482;
}

.menu_bar {
  margin-top: 9px;
  width: 40px;
  height: 40px;
  line-height: inherit;

  .drop_down_menus {
    padding: 0;
  }
}

.menu_bar .menu_bar_button:hover {
  background: none;
}

.table-box-line {
  position: relative;
  padding: 10px 0;
  height: 38px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  color: #20212482;
  box-sizing: border-box;
}
</style>
