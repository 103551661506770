<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      style="position: fixed; background-color: #fff"
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'commbook',
          name: 'Commbook',
        },
        {
          name: `${firstName} ${$route.query.lastName}`,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="1" title="Commbook" class="commbook">
      <div v-for="(item, key) in dataList" :key="key">
        <div v-if="item.isRead != 3" class="commbook-item-wrap">
          <span class="commbook-title">{{ $escape2Html(item.title) }}</span>
          <span class="title">{{ item.topic }}</span>
          <div class="commbook-content">
            <div class="content-header">
              <div class="avatar">
                <head-img
                  :src="item.photo"
                  :firstName="item.pubUserFirstname"
                  :lastName="item.pubUserLastname"
                  line-height="32"
                  @click="() => {}"
                ></head-img>
              </div>
              <span class="name" style="margin-right: 8px">{{
                item.pubUserType == 1
                  ? item.pubUserFirstname
                  : item.pubUserFirstname + " " + item.pubUserLastname
              }}</span>
              <!-- <span
                class="title"
              >{{item.pubUserType==1 ?'Company admin':item.roleName }}</span> -->
              <span class="date">{{ timeFormat(item.createTime) }}</span>
              <span
                class="date"
                style="margin-left: 10px"
                v-if="item.deleted == 2"
                >Edited</span
              >
              <span class="isRead" v-if="item.isRead == 0"></span>
              <span class="menu" v-if="item.pubUserId == user.userId">
                <md-menu md-direction="bottom-end">
                  <md-button class="md-icon-button" md-menu-trigger>
                    <i class="iconfont iconmore_vert-24px" />
                  </md-button>
                  <md-menu-content>
                    <md-menu-item
                      v-if="
                        $buttonPermission('Messages', 'Full') ||
                        $buttonPermission('Messages', 'Edit')
                      "
                      @click="onEditConversationSave(item)"
                      :disabled="
                        Date.now() -
                          Date.parse(
                            UTCDateToLocalDate(item.modifyTime) ||
                              UTCDateToLocalDate(item.createTime)
                          ) >
                        1000 * 60 * 60
                      "
                      >Edit</md-menu-item
                    >
                    <md-menu-item
                      v-if="$buttonPermission('Messages', 'Full')"
                      @click="onDelTopic(item.id)"
                      :disabled="
                        Date.now() -
                          Date.parse(
                            UTCDateToLocalDate(item.modifyTime) ||
                              UTCDateToLocalDate(item.createTime)
                          ) >
                          1000 * 60 * 60 || item.responseDTOList.length > 0
                      "
                      >Delete</md-menu-item
                    >
                  </md-menu-content>
                </md-menu>
              </span>
            </div>
            <div class="content-text">
              <div v-html="getMessage(item)"></div>
              <div
                v-for="(imgItem, key) in item.fileList"
                v-if="item.fileList"
                :key="key"
                style="padding-top: 10px"
              >
                <div
                  :class="`pdf-box `"
                  v-if="/\.pdf$/.test(imgItem)"
                  :title="item.fileDisplayName"
                  @click="onOpenPdfFile(imgItem, item.fileDisplayName)"
                >
                  <canvas :class="`the-canvasA'${Math.random()}`"></canvas>
                  <p class="pdf-intro">
                    <!-- <i class="icon iconfont icona-RemarkIcon_24dp"></i> -->
                    <img
                      :src="require('@/assets/images/icons/attached_pdf.png')"
                      style="width: 20px"
                      alt
                    />
                    {{ item.fileDisplayName }}
                  </p>
                </div>

                <img
                  v-else
                  style="display: block; width: 240px"
                  :src="imgItem"
                  @click="priviewImg(imgItem)"
                />
              </div>
              <!-- <img
			        style="display: block; width: 240px; padding-top: 10px"
			        v-for="(imgItem, key) in item.fileList"
			        :key="key"
			        :src="imgItem"
              />-->
            </div>
          </div>
          <p
            style="margin-bottom: 20px; border-bottom: 1px solid #e0e0e0"
            v-if="item.responseCount"
          ></p>
          <p
            style="margin-bottom: 10px; color: #5f6368; cursor: pointer"
            v-if="item.responseCount"
            @click="
              $set(item, 'responseDTOListShow', !item.responseDTOListShow)
            "
          >
            {{ item.responseCount }} replies
            <i
              class="el-icon-caret-bottom"
              v-if="!item.responseDTOListShow"
            ></i>
            <i class="el-icon-caret-top" v-else></i>
          </p>
          <div
            class="commbook-replies"
            v-for="(resItem, key) in item.responseDTOList"
            :key="key"
            v-show="
              item.responseDTOListShow ||
              key === item.responseDTOList.length - 1
            "
          >
            <div class="content-header">
              <div class="avatar">
                <head-img
                  :src="resItem.photo"
                  :firstName="resItem.responseUserFirstname"
                  :lastName="resItem.responseUserLastname"
                  line-height="32"
                  @click="() => {}"
                ></head-img>
              </div>
              <span class="name" style="margin-right: 8px">{{
                resItem.responseUserType == 1
                  ? resItem.responseUserFirstname
                  : resItem.responseUserFirstname +
                    " " +
                    resItem.responseUserLastname
              }}</span>
              <!-- <span class="title">{{resItem.responseUserType==1?'Company admin':resItem.roleName }}</span> -->
              <span class="date">{{
                timeFormat(resItem.modifyTime) || timeFormat(resItem.createTime)
              }}</span>
              <span
                class="date"
                style="margin-left: 10px"
                v-if="resItem.modifyTime != resItem.createTime"
                >Edited</span
              >
              <span class="isRead" v-if="resItem.isRead == 0"></span>
              <span
                class="menu"
                v-if="
                  ($buttonPermission('Messages', 'Full') ||
                    $buttonPermission('Messages', 'Edit')) &&
                  resItem.responseUser == user.userId &&
                  item.responseDTOList &&
                  item.deleted != 1
                "
              >
                <md-menu md-direction="bottom-end">
                  <md-button class="md-icon-button" md-menu-trigger>
                    <i class="iconfont iconmore_vert-24px" />
                  </md-button>
                  <md-menu-content>
                    <md-menu-item
                      v-if="
                        $buttonPermission('Messages', 'Full') ||
                        $buttonPermission('Messages', 'Edit')
                      "
                      @click="onEditReply(resItem)"
                      :disabled="
                        Date.now() -
                          Date.parse(
                            UTCDateToLocalDate(resItem.modifyTime) ||
                              UTCDateToLocalDate(resItem.createTime)
                          ) >
                        1000 * 60 * 60
                      "
                      >Edit</md-menu-item
                    >
                    <md-menu-item
                      @click="onDelReply(resItem.id)"
                      v-if="$buttonPermission('Messages', 'Full')"
                      :disabled="
                        Date.now() -
                          Date.parse(
                            UTCDateToLocalDate(resItem.modifyTime) ||
                              UTCDateToLocalDate(resItem.createTime)
                          ) >
                        1000 * 60 * 60
                      "
                      >Delete</md-menu-item
                    >
                  </md-menu-content>
                </md-menu>
              </span>
            </div>
            <div class="content-text" v-show="!resItem._isEdit">
              <p>{{ $escape2Html(resItem.response) }}</p>
              <div
                v-for="(imgItem, key) in resItem.fileList"
                :key="key"
                style="padding-top: 10px"
              >
                <!-- <p v-if="/\.pdf$/.test(imgItem)" style="cursor: pointer;" @click="onOpenPdfFile(imgItem)">
			          <i class="icon iconfont icona-RemarkIcon_24dp"></i>
			          This is a PDF document
                </p>-->
                <div
                  :class="`pdf-box `"
                  v-if="/\.pdf$/.test(imgItem)"
                  :title="resItem.fileDisplayNameList[key]"
                  @click="
                    onOpenPdfFile(imgItem, resItem.fileDisplayNameList[key])
                  "
                >
                  <canvas :class="`Bthe-canvas'${Math.random()}`"></canvas>
                  <p class="pdf-intro">
                    <!-- <i class="icon iconfont icona-RemarkIcon_24dp"></i> -->
                    <img
                      :src="require('@/assets/images/icons/attached_pdf.png')"
                      style="width: 20px"
                      alt
                    />
                    {{ resItem.fileDisplayNameList[key] }}
                  </p>
                </div>
                <img
                  v-else
                  style="display: block; width: 240px"
                  :src="imgItem"
                  @click="priviewImg(imgItem)"
                />
              </div>
            </div>
            <!-- <specificReplyInput v-show="resItem._isEdit" :dataItem="resItem" @onReply="onReply" v-if="resItem.fileListTemp && resItem.fileListTemp.length" /> -->
            <div
              class="content-text"
              v-show="resItem._isEdit"
              v-loading="loading"
            >
              <!--  <md-field :class="messageClass">
                <md-input
                  v-model="resItem._repleyValue"
                  md-counter="1000"
                  @input="onChangeRepley"
                  @keyup.enter.native="
			            onEditReplySave(resItem, item.id, item.pubUserId, resItem.id)
			          "
                ></md-input>
                <span class="md-error">Character limit of 1000</span>
              </md-field> -->
              <gg-input
                v-model="resItem._repleyValue"
                :mdCounter="true"
                @input="onChangeRepley"
                :error="errorMessage(resItem._repleyValue)"
                @keyup.enter.native="
                  onEditReplySave(resItem, item.id, item.pubUserId, resItem.id)
                "
                mdCounterNumber="1000"
              ></gg-input>
              <div
                class="pdffff"
                style="padding-top: 10px"
                v-if="resItem.fileListTemp && resItem.fileListTemp.length"
              >
                <imageListPreview
                  :fileList="resItem.fileListTemp || []"
                  :isEdit="resItem._isEdit"
                  @loading="changeloading"
                />
              </div>
              <!-- 	<div v-if="resItem.fileListTemp && resItem.fileListTemp.length"  class="previewImgBox" style="padding-top: 10px;">
							  		  
							  		  <div  class="previewImg">
							  			   <i class="el-icon-close" style="font-size: 15px; cursor: pointer;" @click="resItem.fileListTemp=[]"></i>
							  		  </div>
							  		    <img :src="resItem.fileListTemp[0].src||''" style='width: 240px;' alt="">
              </div>-->
              <base-button @click="$set(resItem, '_isEdit', false)"
                >Cancel</base-button
              >
              <base-button
                type="primary"
                :disabled="
                  !resItem._repleyValue || resItem._repleyValue.length > 1000
                "
                @click="
                  onEditReplySave(resItem, item.id, item.pubUserId, resItem.id)
                "
                >Save</base-button
              >
              <el-upload
                style="display: inline-block; float: right"
                :auto-upload="false"
                action
                accept="image/jpeg, image/png, application/pdf"
                :show-file-list="false"
                :on-change="
                  (file, fileList) => {
                    onUploadFileChange(resItem, file, fileList);
                  }
                "
                v-if="!(resItem.fileListTemp && resItem.fileListTemp.length)"
                :before-upload="beforeUpload"
              >
                <span
                  style="
                    line-height: 32px;
                    cursor: pointer;
                    vertical-align: middle;
                    color: #333;
                    font-size: 18px;
                    margin-right: 6px;
                  "
                  class="icon iconfont iconattach_file-web"
                ></span>
              </el-upload>
            </div>
          </div>
          <div
            class="commbook-input"
            v-if="
              $buttonPermission('Messages', 'Full') ||
              $buttonPermission('Messages', 'Edit') ||
              $buttonPermission('Messages', 'Create')
            "
          >
            <div class="avatar">
              <head-img
                :firstName="user.userInfo.firstName"
                :lastName="user.userInfo.lastName"
                :src="user.userInfo.photo"
                line-height="32"
                @click="() => {}"
              ></head-img>
            </div>
            <specificReplyInput
              v-if="item.fileListTemp && item.fileListTemp.length"
              :dataItem="item"
              @onReply="onReply"
              @loading="changeloading"
            />
            <el-input
              v-else
              maxlength="1000"
              v-model="item._repleyValue"
              @keyup.enter.native="
                onReply($event.target, item.id, item.pubUserId, item)
              "
              @focus="$set(item, 'isFocus', true)"
              @blur="onReplayInputBlur(item)"
              class="reply-input"
              v-loading="loading && loadingId == item.id"
            >
              <span slot="suffix">
                <el-upload
                  style="display: inline-block"
                  :auto-upload="false"
                  action
                  accept="image/jpeg, image/png, application/pdf"
                  :show-file-list="false"
                  :on-change="
                    (file, fileList) => {
                      onUploadFileChange(item, file, fileList);
                    }
                  "
                  :before-upload="beforeUpload"
                >
                  <!-- <i
			            v-show="item.isFocus"
			            class="el-icon-link"
			            style="line-height: 32px; cursor: pointer; vertical-align: middle; color: #333; font-size: 20px; margin-right: 6px;"
                  ></i>-->
                  <span
                    style="
                      color: #202124;
                      line-height: 32px;
                      cursor: pointer;
                      vertical-align: middle;
                      font-size: 18px;
                      margin-right: 6px;
                    "
                    v-if="item.isFocus"
                    class="icon iconfont iconattach_file-web"
                  ></span>
                </el-upload>

                <i
                  class="iconfont iconsend"
                  :class="{ isFocus: item._repleyValue }"
                  style="
                    line-height: 32px;
                    cursor: pointer;
                    vertical-align: middle;
                    display: inline-block;
                  "
                  @click="
                    onReply(
                      { value: item._repleyValue },
                      item.id,
                      item.pubUserId,
                      item
                    )
                  "
                ></i>
              </span>
            </el-input>
          </div>
        </div>
        <div v-if="item.isRead == 3">
          <span
            style="
              display: block;
              width: 100%;
              padding: 32rpx 0;
              box-sizing: border-box;
            "
            class="base-flex-between"
          >
            <span
              style="
                display: block;
                width: 100%;
                height: 1px;
                background-color: #1a73e8;
              "
            ></span>
            <span
              style="
                display: block;
                color: #1a73e8;
                font-size: 12px;
                font-weight: 500;
                padding: 0 6px;
              "
              >UNREAD</span
            >
            <span
              style="
                display: block;
                width: 100%;
                height: 1px;
                background-color: #1a73e8;
              "
            ></span>
          </span>
        </div>

        <!-- <div style="padding-top: 10px;" v-if="item.fileListTemp && item.fileListTemp.length">
          <imageListPreview :fileList="item.fileListTemp || []" />
        </div>-->
      </div>
      <el-button
        type="primary"
        round
        size="mini"
        style="width: 100%; margin-bottom: 20px"
        v-if="unreadMsgCount && isShowNew"
        @click="onLoadMore"
        >{{ unreadMsgCount }} new messages</el-button
      >
      <!-- 开始会话 -->
      <div
        class="commbook-header mar-b-20"
        v-show="!isShowRecipientsView"
        v-if="
          $buttonPermission('Messages', 'Full') ||
          $buttonPermission('Messages', 'Edit') ||
          $buttonPermission('Messages', 'Create')
        "
      >
        <head-img
          class="user-box"
          :firstName="user.userInfo.firstName"
          :lastName="user.userInfo.lastName"
          :src="user.userInfo.photo"
          line-height="36"
          @click="() => {}"
        ></head-img>
        <div style="cursor: pointer" @click="isShowRecipientsView = true">
          Start a conversation with {{ firstName }} ...
        </div>
      </div>
      <recipientsView
        :studentId="studentId"
        v-if="isShowRecipientsView"
        @onCancel="isShowRecipientsView = false"
        @onSave="onRecipientsSave"
      />

      <recipientsView1
        :paramsDetail="paramsDetail"
        :studentId="studentId"
        v-if="isShowEditConversation"
        @cancel="item.isShowEditConversation = false"
        :isShowDialog="true"
        @onCancel="isShowEditConversation = false"
        @onSave="editConversationed"
      />
      <p style="height: 20px"></p>
      <div v-if="!dataList.length">
        <!-- 空状态 -->
        <edoovo-table height="100%" rowKey="id" defaultValue="-">
          <div slot="empty" style="text-align: center">
            <div
              style="
                width: 213px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 12px;
                line-height: 200px;
              "
            >
              <img
                style="width: 100%"
                :src="require('@/assets/images/products/noData.svg')"
              />
            </div>
            <p style="font-size: 22px">No message yet.</p>
          </div>
        </edoovo-table>
      </div>
    </GgPage>
    <gg-view-img
      v-if="isViewImg"
      :visible.sync="isViewImg"
      :src="viewImg"
      name="Preview"
    />
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import recipientsView from "./components/recipients";
import recipientsView1 from "./components/recipientsCon";
import specificReplyInput from "./components/specific-reply-input";
import imageListPreview from "@/components/private-components/ImageListPreview/index1.vue";
import moment from "moment";
import { IDENTITY_MATRIX } from "pdfjs-dist/build/pdf.worker";
const PDFJS = require("pdfjs-dist");
PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry");
export default {
  components: {
    recipientsView,
    recipientsView1,
    imageListPreview,
    specificReplyInput,
  },
  data() {
    return {
      isViewImg: false,
      viewImg: "",
      setNewMsgDataTimerId: null,
      hasErrorMessages: false,
      firstName: "",
      unreadMsgCount: 0,
      dataList: [],
      queryParams: {
        size: 20,
        current: 1,
        studentId: "",
      },
      pagesTotal: 1,
      studentId: "",
      isShowRecipientsView: false,
      snackbar: {
        isShow: false,
        content: "",
      },
      string: Math.random(),
      pdfnum: 0,
      pages: [],
      pdfPages: [],
      is_loading: false,
      isShowEditConversation: false,
      paramsDetail: {},
      isShowNew: true,
      loading: false,
      loadingId: "",
    };
  },
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    messageClass() {
      return {
        "md-invalid": this.hasErrorMessages,
      };
    },
  },
  beforeRouteLeave(to, from, next) {
    this.setReadMsgData();
    next();
  },
  created() {
    this.studentId = this.$route.query.studentId; // '100'
    this.firstName = this.$route.query.firstName;
    this.getMsgData();
    this.getMsgCount();
    this.setNewMsgDataTimer();
    // this.setReadMsgData()
    this.$nextTick(() => {
      let el = document.querySelector(".ggPage-main-center.centerPadding1");
      el.onscroll = () => {
        if (el.scrollTop <= 30) {
          if (this.queryParams.current >= this.pagesTotal) return false;
          this.queryParams.current++;
          let scrollHeightBefore = el.scrollHeight;
          this.getMsgData(true).then((res) => {
            let scrollHeightAfter = el.scrollHeight;
            // console.log('xxxxxxxxxx', scrollHeightBefore, scrollHeightAfter)
            el.scrollTop = scrollHeightAfter - scrollHeightBefore;
          });
        }
      };
    });
  },
  beforeDestroy() {
    clearTimeout(this.setNewMsgDataTimerId);
  },
  methods: {
    getMessage(item) {
      let message = this.$escape2Html(item.message);
      console.log("getMessage", message);
      message = message.replace(/<p>\s<\/p>/g, "<br/>");
      return message;
    },
    changeloading() {
      this.loading = false;
      this.loadingId = "";
    },
    errorMessage(res) {
      if (res) {
        let nameVal = res;
        let isPass = nameVal.length < 1001;
        return {
          message: "Character limit of 1000",
          show: !isPass,
        };
      }
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 25; //这里做文件大小限制
      if (!isLt2M) {
        this.$message({
          message: "Upload files should not exceed 25MB",
          type: "warning",
        });
      }
      return isLt2M;
    },
    editConversationed() {
      this.isShowEditConversation = false;
      this.getNewMsgDatas();
    },
    onEditConversationSave(item) {
      this.isShowEditConversation = true;

      this.paramsDetail = Object.assign({}, item);
    },
    removeFileList() {
      this.resItem.fileListTemp = [];
    },
    priviewImg(e) {
      this.isViewImg = true;
      this.viewImg = e;
      // window.open(`../testFileView?imgUrl=${e}&name=preview&needBack=1`,'_self');
    },
    timeFormat(date) {
      if (!date) {
        return "-";
      }
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    },
    processingData() {
      //处理PDF插件异步引起的图片顺序问题
    },
    getPdfData() {
      this.dataList.forEach((item, index) => {
        if (item.fileList) {
          item.fileList.forEach((img) => {
            if (/\.pdf$/.test(img)) {
              this.pdfPages.push(img);
            }
          });
        }
        if (item.responseDTOList) {
          item.responseDTOList.forEach((resItem) => {
            if (resItem.fileList) {
              resItem.fileList.forEach((img_r) => {
                if (/\.pdf$/.test(img_r)) {
                  this.pdfPages.push(img_r);
                }
              });
            }
          });
        }
      });

      if (this.pdfPages.length > 0) {
        setTimeout(() => {
          this.pdfnum = 0;
          console.log(this.pdfPages);
          this._loadFile();
        }, 500);
      }
    },
    _loadFile() {
      if (this.is_loading) {
        return false;
      }
      let url = this.pdfPages[this.pdfnum];
      console.log(this.pdfnum);
      console.log(url);
      this.loading = true;
      PDFJS.getDocument(url)
        .promise.then((pdf) => {
          this.pdfDoc = pdf;
          this.pages = this.pdfDoc.numPages;
          // console.log('document函数')
          this.$nextTick(() => {
            this._renderPage();
            this.is_loading = true;
          });
        })
        .catch(() => {
          this.pdfnum += 1;
          this._loadFile();
          this.loading = false;
        });
    },
    _renderPage() {
      this.pdfDoc.getPage(1).then((page) => {
        let canvas = document.getElementsByTagName(`canvas`)[this.pdfnum];

        var vp = page.getViewport({ scale: 1 });
        let ctx = canvas.getContext("2d");
        let dpr = window.devicePixelRatio || 1;
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;
        let ratio = dpr / bsr;
        let viewport = page.getViewport({
          scale: window.innerWidth / vp.width,
        });
        canvas.width = viewport.width * ratio;
        canvas.height = viewport.height * ratio;
        canvas.style.width = 240 + "px";
        canvas.style.height = 240 + "px";
        ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
        this.is_loading = false;
        this.loading = false;

        // if (this.pages > num) {
        //     this._renderPage(num + 1)
        // }
        this.pdfnum += 1;
        if (this.pdfnum < this.pdfPages.length) {
          this._loadFile();
        }
      });
    },
    onOpenPdfFile(url, name) {
      let needPrint = 1;
      // window.open(
      //   `${url}`
      // );
      // console.log(process)
      let newUrl = this.$router.resolve({
        path: `/testFileView?url=${url}&name=${name}&${needPrint}`,
      });
      window.open(newUrl.href, "_blank");
    },
    onUploadFileChange(item, file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 25; //这里做文件大小限制
      if (!isLt2M) {
        this.$message({
          message: "Upload files should not exceed 25MB",
          type: "warning",
        });
        return isLt2M;
      }
      this.loading = true;
      this.loadingId = item.id;
      if (!item.fileListTemp) this.$set(item, "fileListTemp", []);
      if (file) {
        let fileName = file.name;
        let formData = new FormData();
        formData.append("file", file);
        formData.append("multipartFile", file.raw);
        Ajax.post(
          // "/media/public/file/upload",
          "/media/public/file/uploadExt",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
          .then((res) => {
            if (res.code === "0000") {
              let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
              item.fileListTemp.push({
                fileName,
                src: imgSrc,
                id: res.data.requestId,
              });
              this.loading = true;
              console.log(this.loading);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onReplayInputBlur(item) {
      setTimeout(() => {
        this.$set(item, "isFocus", false);
      }, 300);
    },
    onChangeRepley(text) {
      if (text.length > 1000) {
        this.hasErrorMessages = true;
      } else {
        this.hasErrorMessages = false;
      }
    },
    onRecipientsSave() {
      this.isShowRecipientsView = false;
      this.getNewMsgDatas();
    },
    onLoadMore() {
      this.getNewMsgData().then(() => {
        this.isShowNew = false;
        this.getMsgCount();
        this.setReadMsgData();
      });
    },
    onEditTopic(item) {
      console.log(item);
      item.isShowEditConversation = true;
    },
    onDelTopic(id) {
      this.$confirm(
        "Once deleted, parents cannot see this message and comments will also be deleted.",
        "Delete conversation?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      ).then(() => {
        this.delTopic(id);
      });
    },
    onDelReply(id) {
      this.$confirm(
        "Once deleted, parents cannot see this message and you will not be able to recover the data.",
        "Delete reply?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      ).then(() => {
        this.delReply(id);
      });
    },
    onEditReply(resItem) {
      this.$set(resItem, "_repleyValue", resItem.response);
      this.$set(resItem, "_isEdit", true);
      let fileList = [];
      resItem.fileList.forEach((item) => {
        fileList.push({
          fileName: Date.now(),
          src: item,
          id: Date.now(),
        });
      });
      this.$set(resItem, "fileListTemp", fileList || []);
    },
    onEditReplySave(resItem, commbookId, pubUserId, id) {
      let responseMsg = resItem._repleyValue;
      if (!responseMsg) return false;
      let fileList = [];
      resItem.fileListTemp &&
        resItem.fileListTemp.forEach((fileItem) => {
          fileList.push(fileItem.src);
        });
      let params = {
        id,
        commbookId,
        pubUserId,
        response: responseMsg,
        responseUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        responseUserType: 2,
        fileList,
        fileDisplayNameList: [
          (resItem.fileListTemp && resItem.fileListTemp[0].fileName) || "",
        ],
      };
      Ajax.post("/commbook/response/update", params).then((res) => {
        this.getNewMsgDatas();
        this.$set(resItem, "_isEdit", false);
      });
      resItem.fileListTemp = [];
    },
    onReply(targetObj, commbookId, pubUserId, item) {
      let responseMsg = targetObj.value;
      if (!responseMsg && !item.fileListTemp) return false;
      let fileList = [];
      item.fileListTemp &&
        item.fileListTemp.forEach((fileItem) => {
          fileList.push(fileItem.src);
        });
      let params = {
        commbookId,
        pubUserId,
        response: responseMsg,
        responseUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        responseUserType: 2,
        fileList,
        studentId: this.$route.query.studentId,
        fileDisplayNameList: [
          (item.fileListTemp && item.fileListTemp[0].fileName) || "",
        ],
      };
      Ajax.post("/commbook/response/response", params).then((res) => {
        this.getNewMsgDatas();

        targetObj.value = "";
      });
      item.fileListTemp = [];
    },
    getNewMsgDatas() {
      //编辑删除回复或发布主题回调
      this.queryParams.studentId = this.studentId;
      this.queryParams.schoolId = this.user.locationId;
      this.queryParams.userId = this.user.userId;
      return Ajax.post("/commbook/title/getList", this.queryParams).then(
        (res) => {
          let { records } = res.data;
          this.dataList = records;
          this.pdfPages = [];
          this.getPdfData();
          this.goPageBottom();
        }
      );
    },
    delTopic(id) {
      let params = {
        id,
        userId: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
      };
      Ajax.post("/commbook/title/delete", {}, {}, params).then((res) => {
        this.getNewMsgDatas();
        this.snackbar = {
          isShow: true,
          content: "Conversation has been deleted",
        };
      });
    },
    delReply(id) {
      let params = {
        id,
        userId: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
      };
      Ajax.post("/commbook/response/delete", {}, {}, params).then((res) => {
        this.getNewMsgDatas();
        this.snackbar = { isShow: true, content: res.message };
      });
    },
    setNewMsgDataTimer() {
      this.setNewMsgDataTimerId = setTimeout(() => {
        this.getMsgCount();
        this.setNewMsgDataTimer();
      }, 60000);
    },
    setReadMsgData() {
      let params = {
        schoolId: this.user.locationId,
        studentId: this.studentId,
        userId: this.user.userId,
      };
      Ajax.post("/commbook/title/setRead", params);
    },
    getMsgData(isStopGoBottom) {
      this.queryParams.studentId = this.studentId;
      this.queryParams.schoolId = this.user.locationId;
      this.queryParams.userId = this.user.userId;
      return Ajax.post("/commbook/title/getList", this.queryParams).then(
        (res) => {
          let { records, pages } = res.data;
          this.pagesTotal = pages;
          for (let i = records.length - 1; i >= 0; i--) {
            this.dataList.unshift(records[i]);
          }

          this.getPdfData();
          !isStopGoBottom && this.goPageBottom();
        }
      );
    },
    getNewMsgData() {
      this.queryParams.studentId = this.studentId;
      this.queryParams.schoolId = this.user.locationId;
      this.queryParams.userId = this.user.userId;
      return Ajax.post("/commbook/title/getNewList", this.queryParams).then(
        (res) => {
          let { records } = res.data;
          this.dataList = records;
          this.getPdfData();
          this.goPageBottom();
        }
      );
    },
    getMsgCount() {
      let params = {
        userId: this.user.userId,
        studentId: this.studentId,
        schoolId: this.user.locationId,
      };
      Ajax.post("/commbook/title/getCommbookTotalCount", {}, {}, params).then(
        (res) => {
          if (res.code === "0000") {
            this.unreadMsgCount = res.data;
          }
        }
      );
    },
    goPageBottom() {
      this.$nextTick(() => {
        let el = document.querySelector(".ggPage-main-center.centerPadding1");
        //判断元素是否出现了滚动条
        if (el.scrollHeight > el.clientHeight) {
          //设置滚动条到最底部
          el.scrollTop = el.scrollHeight;
        }
      });
    },
    UTCDateToLocalDate(dateStr) {
      if (!dateStr) return false;
      let date1 = new Date();
      let offsetMinute = date1.getTimezoneOffset();
      let offsetHours = offsetMinute / 60;
      let date2 = new Date(dateStr);
      date2.setHours(date2.getHours() - offsetHours);
      return Utils.formatTime(Date.parse(date2));
    },
  },
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
/deep/ .el-upload-list {
  display: none;
}
/deep/.ggPage {
  // height: auto;
  background-color: #fff;
}
/deep/.ggPage-main-center {
  // overflow-y: initial;
}
// .md-textarea-custom .md-invalid {
//   border: 0 !important;
//   border-bottom: 1px solid !important;
// }
// .md-textarea-custom:after {
//   border: 0 !important;
//   border-bottom: 1px solid !important;
// }
// .md-textarea-custom textarea::-webkit-scrollbar {
//   width: 0px !important;
// }
.reply-input {
  /deep/.el-input__inner {
    border-radius: 50px;
    height: 32px;
    line-height: 32px;
    padding-right: 65px;
  }

  .isFocus {
    color: #448aff;
  }
}
.commbook-item-wrap {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  .commbook-title {
    padding: 20px 0px;
    margin-bottom: 20px;
    // border-bottom: 1px solid #E0E0E0;
    font-size: 22px;
    font-weight: bold;
    display: inline-block;
  }
  .commbook-content {
    // margin-bottom: 20px;
    padding-bottom: 20px;
    // border-bottom: 1px solid #e0e0e0;
  }
  .commbook-replies {
    margin-bottom: 10px;
  }
  .commbook-content,
  .commbook-replies {
    // margin-bottom: 10px;
    // padding-bottom: 20px;
    // border-bottom: 1px solid #E0E0E0;
    .content-header {
      font-size: 12px;
      span {
        vertical-align: middle;
      }
      .avatar {
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      .name {
        font-size: 14px;
        font-weight: bold;
      }

      .date {
        color: #5f6368;
      }
      .menu {
        display: none;
        float: right;
      }
      &:hover .menu {
        display: block;
      }
    }
    .content-text {
      padding: 10px 0px;
      padding-left: 40px;
      color: #202124;
      // font-weight: bold;
    }
  }
  .commbook-input {
    display: flex;
    padding-top: 20px;
    .avatar {
      // display: inline-block;
      // vertical-align: middle;
      margin-right: 8px;
      // padding-top: 20px;
    }
    border-top: 1px solid #e0e0e0;
  }
}
.title {
  padding: 3px 8px;
  margin: 0px 8px;
  background-color: #e0e0e0;
  border-radius: 50px;
  // font-weight: bold;
}
.commbook {
  &-header {
    display: flex;
    align-items: center;
    padding: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    color: rgba(32, 33, 36, 0.5);
    font-family: Roboto;
    border-radius: 13px;
    .user-box {
      margin-right: 28px;
    }
  }
}
.pdf-intro {
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pdf-box {
  position: relative;
  cursor: pointer;
}
.previewImgBox {
  position: relative;
  width: 240px;
  .previewImg {
    position: absolute;
    top: 20px;
    right: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    padding: 3px;
  }
}
.base-flex-between {
  display: flex !important;
  align-items: center;
  margin: 20px 0;
}
.isRead {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #448aff;
  border-radius: 50%;
  margin-left: 8px;
  /* float: right; */
}
.iconsend {
  margin: 0 10px;
  margin-left: 3px;
}
.md-field.md-theme-default.md-focused .md-input {
  /* color: ; */
  color: black !important;
}
/deep/.md-button-content {
  display: flex;
  align-items: center;
}
</style>
