<!-- commbook -->
<template>
  <div class="recipients" v-loading="loading">
    <base-button
      type="primary"
      v-if="isSelect"
      @click="selectStudentsDialogVisible = true"
      >Select students</base-button
    >
    <div class="selected-student-list" v-if="isSelect">
      <p
        v-for="(item, key) in chooseStudentObj"
        :key="key"
        v-show="item.count && item.studentList.length"
      >
        <head-img
          class="user-box"
          line-height="28"
          :firstName="
            item.studentList.length > 0 ? item.studentList[0].className : ''
          "
          @click="() => {}"
          :lastName="
            item.studentList.length > 0
              ? item.studentList[0].className.split(' ').length > 1
                ? item.studentList[0].className.split(' ')[1]
                : ''
              : ''
          "
          :style="{ backgroundColor: item.themeColor }"
          style="display: inline-block; margin-right: 10px; vertical-align: top"
        ></head-img>
        <span v-for="(sItem, key) in item.studentList" :key="key">
          {{ sItem.firstName }} {{ sItem.lastName }}
          <i
            class="el-icon-error"
            style="cursor: pointer; color: #cecece"
            @click="onDelStudent(item.studentList, key)"
          ></i>
        </span>
      </p>
    </div>
    <div>
      <div style="overflow: hidden; margin-bottom: 10px">
        <md-field style="float: left; width: 48%; background-color: #f8f8f8">
          <label for="movie">Topic</label>
          <md-select v-model="paramsDetail.topic" name="movie" id="movie">
            <md-option
              :value="item.value"
              v-for="(item, key) in topicList"
              :key="key"
              >{{ item.value }}</md-option
            >
          </md-select>
        </md-field>
        <gg-input
          v-model="paramsDetail.title"
          placeholder="Title"
          :error="errorTitle"
          style="float: right; width: 48%; background-color: #f8f8f8"
        />
      </div>
      <div class="edit-editing">
        <!-- <gg-input
		    v-model="paramsDetail.message"
		    placeholder="Add message"
		    :error="errorMessage"
		    style="background-color: #f8f8f8;"
			filedType='textarea'
		  /> -->
        <editor :init="editorInit" v-model="paramsDetail.message" />
      </div>
      <div class="edit-error" v-if="errorMessage.show">
        {{ errorMessage.message }}
      </div>
      <!-- <md-field style="background-color: #f8f8f8;">
        <label>Add message</label>
        <md-textarea v-model="paramsDetail.message"></md-textarea>
      </md-field> -->
      <imageListPreview :fileList="fileList" @loading="changeloading" />
      <!--  <div v-if="fileList.length>0"  class="previewImgBox">
		  
		  <div  class="previewImg">
			   <i class="el-icon-close" style="font-size: 15px; cursor: pointer;" @click="removeFileList()"></i>
		  </div>
		  
		    <img :src="fileList.length>0?fileList[0].src:''" style='width: 240px;' alt="">
	  </div> -->

      <div style="overflow: hidden; padding-top: 20px">
        <el-upload
          style="display: inline-block"
          :auto-upload="false"
          action=""
          accept="image/jpeg,image/png,application/pdf"
          :show-file-list="false"
          :on-change="
            (file, fileList) => {
              onUploadFileChange(file, fileList);
            }
          "
          :before-upload="beforeUpload"
          :disabled="fileList.length != 0"
        >
          <!-- <i
		      class="el-icon-link"
		      style="line-height: 32px; cursor: pointer; vertical-align: middle; color: #333; font-size: 20px; margin-right: 6px;"
		    ></i> -->
          <!-- <span  style="line-height: 32px; cursor: pointer; vertical-align: middle; color: #333; font-size: 20px; margin-right: 6px;" class="icon iconfont iconattach_file-web"></span> -->
          <!-- <baseButton>Cancel</baseButton> -->
          <baseButton
            :disabled="fileList.length != 0"
            style="margin-top: 8px"
            type="primary"
            class="contained"
            ><span class="icon iconfont iconattach_file-web"></span
            >Add</baseButton
          >
        </el-upload>

        <!-- <el-button class="add-file-btn" size="small" type="primary">
		  <img class="add-file-icon" src="@/assets/images/icons/attach_file_bai.svg" />
		  Add
		</el-button> -->
        <div style="float: right">
          <base-button @click="onCancel">Cancel</base-button>
          <base-button
            :title="
              !(
                paramsDetail.message &&
                paramsDetail.title &&
                paramsDetail.topic
              ) ||
              (isSelect && JSON.stringify(chooseStudentObj) == '{}') ||
              errorTitle.show ||
              errorMessage.show
                ? 'Cannot post without students,topic and message'
                : ''
            "
            type="primary"
            @click="onPost"
            :disabled="
              !(
                paramsDetail.message &&
                paramsDetail.title &&
                paramsDetail.topic
              ) ||
              (isSelect && JSON.stringify(chooseStudentObj) == '{}') ||
              errorTitle.show ||
              errorMessage.show
            "
            >Post</base-button
          >
        </div>
      </div>
    </div>
    <!-- dialog -->
    <img-cut-modal
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      :acceptPdfFile="true"
      @_uploadCallback="_uploadCallback"
      @_uploadPdfCallback="_uploadPdfCallback"
      :isCut="true"
    />
    <selectStudentsDialog
      :dialogVisible="selectStudentsDialogVisible"
      :studentListObj="studentListObj"
      :selectedStudentObj="chooseStudentObj"
      @cancel="selectStudentsDialogVisible = false"
      @save="onSelectStudents"
    />
    <Snackbar :visible.sync="snackbar.isShow" :content="snackbar.content" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import imageListPreview from "@/components/private-components/ImageListPreview/index1.vue";
import selectStudentsDialog from "../dialog-modules/select-students-dialog.vue";
import Editor from "@tinymce/tinymce-vue";
export default {
  name: "abc",
  components: {
    imageListPreview,
    selectStudentsDialog,
    Editor,
  },
  props: {
    isShowDialog: {
      type: Boolean,
      default: false,
    },
    isSelect: {
      type: Boolean,
      default: false,
    },
    studentListObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    studentId: {
      tyep: String,
      default: "",
    },
  },

  data() {
    return {
      topicList: [],
      isImgCutShow: false,
      chooseStudentObj: {},
      selectStudentsDialogVisible: false,
      fileList: [],
      paramsDetail: {
        topic: "",
        title: "",
        message: "",
      },
      snackbar: {
        isShow: false,
        content: "",
      },
      loading: false,
      editorInit: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    errorTitle() {
      let nameVal = this.paramsDetail.title;
      let isPass = nameVal.length < 40;
      return {
        message: "Character limit of 40",
        show: !isPass,
      };
    },
    errorMessage() {
      let nameVal = this.paramsDetail.message;
      let isPass = nameVal.length < 5000;
      console.log("errorMessage", isPass);
      return {
        message: "Character limit of 5000",
        show: !isPass,
      };
    },
  },
  created() {
    let that = this;
    this.editorInit = {
      language: "en",
      convert_urls: false,
      statusbar: false,
      menubar: false, // 禁用菜单栏
      branding: false, // 隐藏右下角技术支持
      elementpath: false, // 隐藏底栏的元素路径
      advlist_bullet_styles: "",
      advlist_number_styles: "",
      plugins: [
        "autoresize advlist anchor autolink autosave code codesample colorpicker colorpicker directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount",
      ],
      toolbar: ["bold italic underline | bullist numlist link"],
      anchor_top: false,
      anchor_bottom: false,
      paste_preprocess: function (plugin, args) {
        args.content = that.strip_tags(
          args.content,
          "<strong><p><i><u><em><h1><h2><h3><h4><h5>"
        );
      },
      autoresize_bottom_margin: 50,
      autoresize_max_height: 245, // 编辑区域的最大高
      autoresize_min_height: 245, // 编辑区域的最小高度
      plugin_preview_width: 1000,
      autoresize_on_init: true,
      autoresize_overflow_padding: 10,
      images_upload_handler: function (blobInfo, success, failure) {
        const formData = new FormData();
        formData.append("file", blobInfo.blob());
      },
    };
  },
  mounted() {
    this.queryTopicList();
  },
  methods: {
    strip_tags(_str, allowed_tags) {
      var str = _str
        .replace(new RegExp("<h(.*?)>", "g"), "<strong>")
        .replace(new RegExp("</h(.*?)>", "g"), "</strong>");
      console.log(str);
      var key = "",
        allowed = false;
      var matches = [];
      var allowed_array = [];
      var allowed_tag = "";
      var i = 0;
      var k = "";
      var html = "";
      var replacer = function (search, replace, str) {
        return str.split(search).join(replace);
      };
      // Build allowes tags associative array
      if (allowed_tags) {
        allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
      }
      console.log(allowed_tags, allowed_array);
      str += "";
      // Match tags
      matches = str.match(/(<\/?[\S][^>]*>)/gi);
      // Go through all HTML tags
      for (key in matches) {
        if (isNaN(key)) {
          // IE7 Hack
          continue;
        }
        // Save HTML tag
        html = matches[key].toString();
        // Is tag not in allowed list? Remove from str!
        allowed = false;
        // Go through all allowed tags
        for (k in allowed_array) {
          // Init
          allowed_tag = allowed_array[k];
          i = -1;
          if (i != 0) {
            i = html.toLowerCase().indexOf("<" + allowed_tag + ">");
          }
          if (i != 0) {
            i = html.toLowerCase().indexOf("<" + allowed_tag + " ");
          }
          if (i != 0) {
            i = html.toLowerCase().indexOf("</" + allowed_tag);
          }

          // Determine
          if (i == 0) {
            allowed = true;
            break;
          }
        }
        if (!allowed) {
          str = replacer(html, "", str); // Custom replace. No regexing
        }
      }
      return str;
    },
    changeloading() {
      this.loading = false;
    },
    beforeUpload(file) {
      console.log(file);
    },
    onUploadFileChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 25; //这里做文件大小限制
      console.log(isLt2M);
      if (!isLt2M) {
        this.$message({
          message: "Upload files should not exceed 25MB",
          type: "warning",
        });
        return isLt2M;
      }
      this.loading = true;
      if (file) {
        let fileName = file.name;
        let formData = new FormData();
        formData.append("file", file);
        formData.append("multipartFile", file.raw);
        Ajax.post(
          // "/media/public/file/upload",
          "/media/public/file/uploadExt",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
          .then((res) => {
            if (res.code === "0000") {
              let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
              this.fileList.push({
                fileName,
                src: imgSrc,
                id: res.data.requestId,
              });
            }
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    removeFileList() {
      this.fileList = [];
    },
    _uploadCallback(formData, fileData) {
      let fileName = fileData.name;
      formData.append("multipartFile", fileData.raw);
      this.isImgCutShow = false;
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.fileList.push({
            fileName,
            src: imgSrc,
            id: res.data.requestId,
          });
        })
        .catch(() => {});
    },
    _uploadPdfCallback(file) {
      let fileName = file.name;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("multipartFile", file.raw);
      this.isImgCutShow = false;
      Ajax.post("/media/public/file/uploadExt", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          let imgSrc = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`;
          this.fileList.push({
            fileName,
            src: imgSrc,
            id: res.data.requestId,
          });
        })
        .catch(() => {});
    },
    onSelectStudents(studentList) {
      this.chooseStudentObj = studentList;
      console.log(this.chooseStudentObj);
      this.selectStudentsDialogVisible = false;
    },
    onPost() {
      this.loading = true;
      let studentIdList = [];
      let fileList = [];
      if (this.studentId) {
        studentIdList = [this.studentId];
      } else {
        for (let key in this.chooseStudentObj) {
          this.chooseStudentObj[key].studentList &&
            this.chooseStudentObj[key].studentList.forEach((item) => {
              studentIdList.push(item.id);
            });
        }
      }
      console.log(this.fileList);
      this.fileList.forEach((item) => {
        fileList.push(item.src);
      });
      let params = this.paramsDetail;
      Object.assign(params, {
        pubUserId: this.user.userId, // 3fa7f627dda84f3e914f79863e0eda0b
        pubUserType: 2,
        schoolId: this.user.locationId,
        studentIdList,
        fileList,
        fileDisplayNameList: [
          this.fileList && this.fileList[0] ? this.fileList[0].fileName : "",
        ],
      });
      Ajax.post("/commbook/title/publish", params).then((res) => {
        this.loading = false;
        this.snackbar = { isShow: true, content: res.message };
        this.$emit("onSave");
      });
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onDelStudent(list, index) {
      list.splice(index, 1);
    },
    queryTopicList() {
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: "commbook",
        dataType: "topic",
        userId: this.user.userId,
      }).then((res) => {
        this.topicList = res.data || [];
      });
    },
  },
};
</script>
<style lang="less">
.recipients {
  //编辑器
  .mce-panel {
    background: #dcddde;
    height: 56px;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
    border: 0;
    box-sizing: border-box;
  }

  .mce-btn {
    background: #dcddde;
    box-shadow: none;

    &:hover {
      background: #dcddde;
      border-color: #dcddde;
    }
  }

  .mce-toolbar .mce-btn-group {
    padding: 0;
  }

  .mce-btn button {
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }

  .mce-btn-group:not(:first-child) {
    padding-left: 8px;
    border-left: 1px solid #9c9c9c;
  }

  .mce-ico {
    font-size: 18px;
    line-height: 18px;
    color: #606368;
  }

  .mce-btn-group .mce-btn {
    margin: 0 8px 0 0;
  }

  .mce-top-part::before {
    box-shadow: none;
  }

  .mce-toolbar-grp {
    padding: 16px 0 0 8px;
  }
}
</style>
<style lang="less">
.mce-window {
  padding: 12px 24px !important;
  border-radius: 2px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #cbd6e2 !important;
  height: auto !important;
  left: calc(50% - 165px) !important;
  width: 330px !important;
  &-body {
    width: 100% !important;
    height: auto !important;
  }
}
.mce-window-head {
  border-bottom: none !important;
  padding: 9px 0 !important;
  margin-bottom: 12px !important;
  .mce-title {
    font-size: 16px !important;
    font-family: OpenSans-Bold1 !important;
    color: #33475b !important;
  }
  .mce-close {
    right: -12px !important;
  }
}
.mce-abs-layout-item {
  position: initial !important;
}
.mce-form {
  width: 100% !important;
  height: auto !important;
  > div {
    width: 100% !important;
    height: auto !important;
  }
  .mce-formitem {
    padding-bottom: 16px !important;
    height: auto !important;
    width: 100% !important;
    > .mce-container-body {
      display: flex !important;
      flex-direction: column;
      height: auto !important;
      width: 100% !important;
    }
    label {
      font-size: 14px !important;
      font-family: OpenSans-Bold !important;
      color: #33475b !important;
      margin-bottom: 8px !important;
    }
  }
}

.mce-textbox,
.mce-listbox {
  height: 36px !important;
  background-color: #f5f8fa !important;
  border: 1px solid #cbd6e2 !important;
  border-radius: 2px !important;
  font-family: OpenSans !important;
  font-size: 16px !important;
  color: #33475b !important;
}
.mce-textbox {
  .mce-btn button {
    font-family: OpenSans !important;
    font-size: 16px !important;
    color: #33475b !important;
  }
}
.mce-menu-item.mce-active.mce-menu-item-normal .mce-text,
.mce-menu-item.mce-active.mce-menu-item-normal .mce-ico {
  color: #33475b !important;
}
.mce-menu-item.mce-active.mce-menu-item-normal {
  background: #e5f5f8 !important;
}
.mce-menu-item {
  padding: 12px 4px !important;
  margin-bottom: 0px !important;
  &:hover {
    background: #e5f5f8 !important;
  }
}
.mce-foot {
  border-top: none !important;
  width: 100% !important;
  height: auto !important;
  > .mce-container-body {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
  }
  .mce-btn {
    margin-right: 12px !important;
    border-radius: 2px !important;
    background-color: #eaf0f6 !important;
    border: 1px solid #cbd6e2 !important;
    button {
      font-size: 12px !important;
      font-family: OpenSans !important;
      color: #506e91 !important;
    }
  }
  .mce-primary {
    background-color: #425b76 !important;
    border-color: #425b76 !important;
    button {
      color: #fff !important;
    }
  }
}
</style>
<style lang="less" scoped>
//@import url(); 引入公共css类
.recipients {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.selected-student-list {
  padding: 20px 0px;
  p {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 8px;
    span {
      display: inline-block;
      padding: 4px 15px;
      padding-right: 8px;
      margin-right: 8px;
      border: 1px solid #e0e0e0;
      border-radius: 50px;
      margin-bottom: 8px;
    }
  }
}
/deep/.el-upload-list.el-upload-list--text {
  display: none;
}
/deep/.iconfont {
  margin-right: 5px;
  vertical-align: -0.1em;
}
.previewImgBox {
  position: relative;
  width: 240px;
  .previewImg {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: flex;
    padding: 3px;
  }
}
/deep/.md-field label {
  font-family: Roboto;
  font-size: 14px;
}
/deep/.md-input {
  font-family: Roboto;
  font-size: 14px !important;
  &::placeholder {
    font-size: 14px;
    color: red;
    font-family: Roboto;
  }
}
/deep/.contained {
  background-color: #1a73e8;
  color: #fff !important;
}
/deep/.contained:hover {
  background-color: #2b7de9 !important;
}
/deep/.contained.disabled {
  background-color: #eee !important;
  color: #b4b4b4 !important;
}
/deep/.md-field.md-has-value label {
  font-size: 12px;
}

.edit-editing {
  // margin: 20px;
  height: 300px;
  background: #f5f5f5;
}
.edit-error {
  font-family: OpenSans;
  font-size: 12px;
  color: #ea4335;
}
</style>
